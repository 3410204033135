<template>
  <div class="contact-page">
    <h1>Kontakta oss</h1>
    <p>Vi finns här för att svara på dina frågor och ta emot feedback.</p>
    <h2>Kontaktinformation</h2>
    <ul>
      <li>E-post: info@bananoutdoor.com</li>
      <li>Postadress: Ejdergatan 6, 619 32 Trosa</li>
      <li>Besöksadress: Friluftsvägen, 619 34 Trosa</li>
      <li>Org.nr: 802547-2534</li>
    </ul>
    <h2>Kontaktformulär</h2>
    <form @submit.prevent="submitForm">
      <input v-model="name" type="text" placeholder="Ditt namn" required>
      <input v-model="email" type="email" placeholder="Din e-postadress" required>
      <textarea v-model="message" placeholder="Ditt meddelande" required></textarea>
      <button type="submit">Skicka</button>
    </form>
  </div>
</template>

<script>
import { ref } from 'vue'

export default {
  name: 'ContactPage',
  setup() {
    const name = ref('')
    const email = ref('')
    const message = ref('')

    const submitForm = () => {
      // Implement form submission logic here
      console.log('Form submitted:', { name: name.value, email: email.value, message: message.value })
    }

    return { name, email, message, submitForm }
  }
}
</script>
