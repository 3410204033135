<template>
    <div class="prices-page">
      <h1>Priser</h1>
  
      <!-- Membership Section -->
      <section class="pricing-section">
        <h2>Medlemskap</h2>
        <p>Årsmedlemskap: <strong>100 kr</strong></p>
        <p>
          Ett medlemskap krävs för att köpa ett träningskort. Medlemskapet är inte berättigat för Friskvårdsbidrag enligt skatteverkets nuvarande regler.
        </p>
      </section>
  
      <!-- Training Card Section -->
      <section class="pricing-section">
        <h2>Träningskort</h2>
        <ul>
          <li>Årskort: <strong>1400 kr</strong></li>
        </ul>
        <p>
          Träningskortet är berättigat för Friskvårdsbidrag.
        </p>
      </section>
  
      <!-- Individual Sessions Section -->
      <section class="pricing-section">
        <h2>Enskilda Pass</h2>
        <p>Pris per pass: <strong>50 kr</strong></p>
        <p>
          Enskilda pass kan betalas med Swish på plats när du tränar eller vid annat tillfälle via Swish till <strong>123-0947481</strong>.
        </p>
      </section>
  
      <!-- Payment Methods Section -->
      <section class="payment-methods">
        <h2>Betalningsmetoder</h2>
        <ul>
          <li>Swish</li>
          <li>Bankgiro</li>
          <li>Kontovering</li>
        </ul>
      </section>
  
      <!-- Purchase Process Section -->
      <section class="purchase-process">
        <h2>Hur du köper</h2>
        <ol>
          <li>
            <strong>Registrera och logga in</strong> på din profil sida.
          </li>
          <li>
            Navigera till <strong>Prenumerationer</strong> för att köpa ditt medlemskap eller träningskort.
          </li>
          <li>
            Efter bekräftad betalning är dina kvitton för Friskvårdsbidrag tillgängliga på din profil sida.
          </li>
        </ol>
      </section>
  
      <!-- Additional Information Section -->
      <section class="additional-info">
        <h2>Ytterligare Information</h2>
        <p>
          Efter att du har köpt ett medlemskap eller träningskort kan du enkelt hantera dina köp och se dina kvitton genom att besöka din profil sida under medlemskapsmenyn.
        </p>
      </section>
    </div>
  </template>
  
  <script>
  export default {
    name: 'PricesPage'
  }
  </script>
  
  <style scoped>
  .prices-page {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
  }
  
  .pricing-section,
  .payment-methods,
  .purchase-process,
  .additional-info {
    margin-bottom: 30px;
  }
  
  h1 {
    text-align: center;
    margin-bottom: 40px;
  }
  
  h2 {
    color: #2c3e50;
    margin-bottom: 10px;
  }
  
  ul,
  ol {
    margin-left: 20px;
  }
  
  button {
    background-color: #3498db;
    color: white;
    border: none;
    padding: 10px 15px;
    cursor: pointer;
    border-radius: 5px;
  }
  
  button:hover {
    background-color: #2980b9;
  }
  </style>
