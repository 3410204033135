<template>
  <div
    ref="selectContainer"
    class="custom-select"
    @click="toggleDropdown"
  >
    <div
      class="selected-option"
      :class="{ 'icon-only': !showText }"
    >
      <i :class="selectedOption.icon" />
      <span v-if="showText">{{ selectedOption.name }}</span>
    </div>
    <div
      v-if="isOpen"
      class="options"
    >
      <div
        v-for="(option, value) in options"
        :key="value"
        class="option"
        @click="selectOption(value)"
      >
        <i :class="option.icon" />
        {{ option.name }}
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed, onMounted, onUnmounted } from 'vue'

export default {
  name: 'CustomSelect',
  props: {
    options: {
      type: Object,
      required: true
    },
    modelValue: {
      type: [String, Number],
      required: true
    },
    showText: {
      type: Boolean,
      default: false
    }
  },
  emits: ['update:modelValue'],
  setup(props, { emit }) {
    const isOpen = ref(false)
    const selectContainer = ref(null)

    const selectedOption = computed(() => props.options[props.modelValue] || { name: 'Select an option', icon: '' })

    const toggleDropdown = () => {
      isOpen.value = !isOpen.value
    }

    const selectOption = (value) => {
      emit('update:modelValue', value)
      isOpen.value = false
    }

    const closeDropdown = (event) => {
      if (selectContainer.value && !selectContainer.value.contains(event.target)) {
        isOpen.value = false
      }
    }

    onMounted(() => {
      document.addEventListener('click', closeDropdown)
    })

    onUnmounted(() => {
      document.removeEventListener('click', closeDropdown)
    })

    return {
      isOpen,
      selectedOption,
      toggleDropdown,
      selectOption,
      selectContainer
    }
  }
}
</script>

<style scoped>
.custom-select {
  position: relative;
  width: 100%;
  cursor: pointer;
}

.selected-option {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  min-width: 40px;
}

.selected-option.icon-only {
  width: 40px;
  justify-content: center;
}

.options {
  position: absolute;
  top: 100%;
  left: 0;
  min-width: 150px;
  background-color: white;
  border: 1px solid #ccc;
  border-top: none;
  border-radius: 0 0 4px 4px;
  max-height: 200px;
  overflow-y: auto;
  z-index: 1000;
}

.option {
  padding: 10px;
  display: flex;
  align-items: center;
}

.option:hover {
  background-color: #f0f0f0;
}

i {
  margin-right: 10px;
}

.selected-option i {
  font-size: 1.2em;
}

.selected-option span {
  margin-left: 10px;
}

.icon-only i {
  margin-right: 0;
}
</style>