<template>
  <div class="news-page">
    <h1>Nyheter</h1>
    <div v-if="loading">Laddar nyheter...</div>
    <div v-else-if="error">Ett fel uppstod: {{ error }}</div>
    <div v-else>
      <article v-for="news in newsItems" :key="news.id" class="news-item">
        <h2>{{ news.title }}</h2>
        <p>Publicerad: {{ formatDate(news.publishDate) }}</p>
        <div v-html="news.content"></div>
      </article>
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from 'vue'
import { api } from '@/services/api'

export default {
  name: 'NewsPage',
  setup() {
    const newsItems = ref([])
    const loading = ref(true)
    const error = ref(null)

    const fetchNews = async () => {
      try {
        const response = await api.getNews()
        newsItems.value = response.data
      } catch (err) {
        error.value = err.message
      } finally {
        loading.value = false
      }
    }

    const formatDate = (dateString) => {
      return new Date(dateString).toLocaleDateString('sv-SE')
    }

    onMounted(fetchNews)

    return { newsItems, loading, error, formatDate }
  }
}
</script>
