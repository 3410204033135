<template>
  <li class="menu-item">
    <router-link
      v-if="!item.children && item.id !== 'authentication'"
      :to="item['router-link']"
      class="menu-item-content"
      @click="$emit('closeMenu')"
    >
      <i :class="item.icon"></i>
      <span>{{ item.text }}</span>
    </router-link>
    <div 
      v-else-if="item.id === 'authentication'" 
      class="menu-item-content" 
      @click="$emit('auth-action', item)"
    >
      <i :class="item.icon"></i>
      <span>{{ item.text }}</span>
    </div>
    <div v-else class="menu-item-content" @click.stop="toggleThisSubmenu">
      <i :class="item.icon"></i>
      <span>{{ item.text }}</span>
      <i v-if="item.children && item.children.length > 0" :class="['submenu-indicator', isOpen ? 'fad fa-chevron-up' : 'fad fa-chevron-down']"></i>
    </div>
    <div class="menu-item-description" v-if="isMobile">{{ item.description }}</div>
    <transition name="submenu-fade">
      <ul v-if="item.children && item.children.length > 0" v-show="isOpen" class="submenu">
        <MenuItem
          v-for="child in item.children"
          :key="child.id"
          :item="child"
          :is-mobile="isMobile"
          @closeMenu="$emit('closeMenu')"
          @auth-action="$emit('auth-action', $event)"
        />
      </ul>
    </transition>
  </li>
</template>

<script>
import { computed, inject } from 'vue';
import { useRouter } from 'vue-router';

export default {
  name: 'MenuItem',
  props: {
    item: Object,
    isMobile: Boolean,
    isMainLevel: {
      type: Boolean,
      default: false
    }
  },
  emits: ['closeMenu', 'auth-action'],
  setup(props, { emit }) {
    const isSubmenuOpen = inject('isSubmenuOpen');
    const toggleSubmenu = inject('toggleSubmenu');
    const router = useRouter();

    const isOpen = computed(() => isSubmenuOpen(props.item.id));

    const toggleThisSubmenu = () => {
      if (props.item.children && props.item.children.length > 0) {
        toggleSubmenu(props.item.id, props.isMainLevel);
      } else if (props.item['router-link']) {
        router.push(props.item['router-link']);
        emit('closeMenu');
      }
    };

    return {
      isOpen,
      toggleThisSubmenu,
    };
  },
};
</script>

<style scoped>
.menu-item {
  position: relative;
  list-style: none;
}

.menu-item-content {
  display: flex;
  align-items: center;
  color: white;
  padding: 15px 20px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.menu-item-content:hover {
  background-color: #444;
}

.menu-item-content i {
  margin-right: 10px;
  font-size: 1.2em;
}

.submenu-indicator {
  margin-left: 10px;
  font-size: 0.8em;
}

.submenu {
  display: none;
  background-color: #444;
  min-width: 220px;
  box-shadow: 0 8px 16px rgba(0,0,0,0.2);
  z-index: 1000;
  padding: 0;
  margin: 0;
  list-style: none;
}

/* Show submenu when open */
.submenu:not(.submenu-fade-leave-active) {
  display: block;
}

.submenu .menu-item {
  position: relative;
  width: 100%;
}

.menu-item-description {
  font-size: 14px;
  color: #ccc;
  padding: 0 20px 10px;
}

.submenu-fade-enter-active,
.submenu-fade-leave-active {
  transition: all 0.3s ease;
}

.submenu-fade-enter-from,
.submenu-fade-leave-to {
  opacity: 0;
  transform: translateY(-10px);
}

/* New styles for desktop view */
@media (min-width: 769px) {
  .menu-item > .submenu {
    position: absolute;
    top: 100%;
    left: 0;
  }

  .submenu .menu-item > .submenu {
    top: 0;
    left: 100%;
  }
}

/* Mobile styles */
@media (max-width: 768px) {
  .submenu {
    position: static;
    box-shadow: none;
    width: 100%;
  }

  .submenu .menu-item-content {
    padding-left: 40px;
  }

  .submenu .submenu .menu-item-content {
    padding-left: 60px;
  }
}
</style>
