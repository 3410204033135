<template>
  <div class="ai-enhance-exercise">
    <v-btn @click="enhanceExercise('all')">
      Förbättra alla fält
    </v-btn>
    <v-btn @click="enhanceExercise('description')">
      Förbättra beskrivning
    </v-btn>
    <v-btn @click="enhanceExercise('muscleGroups')">
      Förbättra muskelgrupper
    </v-btn>
    <v-btn @click="enhanceExercise('ratings')">
      Förbättra komplexitet och intensitet
    </v-btn>
  </div>
</template>

<script>
import axios from 'axios';
import anatomiData from '@/assets/anatomi.json';

export default {
  name: 'AIEnhanceExercise',
  props: {
    exercise: {
      type: Object,
      required: true,
    },
    muscleGroups: {
      type: Array,
      required: true,
    },
  },
  setup(props, { emit }) {
    const apiKey = process.env.VUE_APP_OPENAI_API_KEY;

    const enhanceExercise = async (enhanceType) => {
      const prompt = createPrompt(props.exercise, props.muscleGroups, enhanceType);
      
      try {
        console.log('Sending request to OpenAI API...');
        const response = await axios.post(
          'https://api.openai.com/v1/chat/completions',
          {
            model: 'gpt-4o',
            messages: [{ role: 'user', content: prompt }],
            response_format: { type: "json_object" }
          },
          {
            headers: {
              'Authorization': `Bearer ${apiKey}`,
              'Content-Type': 'application/json',
            },
          }
        );

        console.log('Received response from OpenAI API');
        const content = response.data.choices[0].message.content;
        console.log('Raw API response:', content);

        let enhancedData;
        try {
          enhancedData = JSON.parse(content);
        } catch (parseError) {
          console.error('Error parsing JSON:', parseError);
          throw new Error('API returned invalid JSON');
        }

        emit('exercise-enhanced', { enhancedData, enhanceType });
      } catch (error) {
        console.error('Error calling OpenAI API:', error);
        if (error.response) {
          console.error('Response data:', error.response.data);
          console.error('Response status:', error.response.status);
          console.error('Response headers:', error.response.headers);
        } else if (error.request) {
          console.error('No response received:', error.request);
        } else {
          console.error('Error setting up request:', error.message);
        }
        emit('enhance-error', `Ett fel uppstod vid förbättring av övningen: ${error.message}`);
      }
    };

    const createPrompt = (exercise, muscleGroups, enhanceType) => {
      const availableMuscleGroups = anatomiData.map(muscle => muscle.namn).flat();

      let prompt = `Du är en expert på träning och anatomi som ska förklara övningar för vanliga människor. Baserat på följande information om en övning, vänligen förbättra och utöka den på svenska. Använd ett enkelt och lättförståeligt språk utan latinska muskelnamn eller komplicerade termer. Svara med ett JSON-objekt innehållande de förbättrade fälten.

Övning: ${exercise.name}
Beskrivning: ${exercise.description || 'Ingen beskrivning tillgänglig'}
Komplexitet: ${exercise.complexityRating || 'Ej angiven'} (skala 1-10)
Intensitet: ${exercise.intensityRating || 'Ej angiven'} (skala 1-10)
Muskelgrupper: ${exercise.muscleGroups.map(mg => `${mg.text} (${mg.value}): ${mg.intensity || 0}%`).join(', ')}

Tillgängliga muskelgrupper:
${availableMuscleGroups.join(', ')}

`;

      if (enhanceType === 'all' || enhanceType === 'description') {
        prompt += `
Förbättra beskrivningen genom att lägga till mer detaljer om hur övningen utförs, vilka muskler som aktiveras, och eventuella fördelar eller risker. Använd vardagligt språk och undvik tekniska termer. Förklara på ett sätt som en nybörjare lätt kan förstå. Inkludera tips för korrekt utförande och vanliga misstag att undvika.
`;
      }

      if (enhanceType === 'all' || enhanceType === 'muscleGroups') {
        prompt += `
Granska och justera muskelgrupperna och deras intensiteter. Använd endast muskelgrupper från listan över tillgängliga muskelgrupper. Lägg till eventuella saknade relevanta muskelgrupper och justera intensiteterna för att bättre representera övningen. Var noga med att variera intensiteterna baserat på övningens natur. Primära muskelgrupper bör ha högre intensitet (70-100%), sekundära muskelgrupper bör ha medelhög intensitet (40-70%), och stödjande muskelgrupper bör ha lägre intensitet (10-40%). Inkludera endast muskelgrupper som är relevanta för övningen.
`;
      }

      if (enhanceType === 'all' || enhanceType === 'ratings') {
        prompt += `
Granska och justera komplexitets- och intensitetsbetyget baserat på övningens natur och beskrivning. Tänk på att använda hela skalan från 1 till 10, där 1 är mycket låg och 10 är mycket hög. Förklara kort varför du valt dessa värden med enkla ord.
`;
      }

      prompt += `
Svara med ett JSON-objekt som innehåller de förbättrade fälten. Exempel:
{
  "description": "Förbättrad beskrivning här, skriven på ett enkelt och lättförståeligt sätt...",
  "muscleGroups": [
    { "name": "Stora bröstmuskeln", "intensity": 85 },
    { "name": "Axelmuskel", "intensity": 60 },
    { "name": "Tricepsmuskel", "intensity": 30 }
  ],
  "complexityRating": 7,
  "intensityRating": 8,
  "ratingExplanation": "Kort förklaring av komplexitets- och intensitetsbetyget här..."
}

Använd endast muskelgrupper från den tillgängliga listan i ditt svar.
`;

      return prompt;
    };

    return {
      enhanceExercise,
    };
  },
};
</script>