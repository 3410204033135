<template>
  <div class="edit-sessions-page">
    <h1>Hantera pass</h1>
    
    <!-- Search and create section -->
    <div class="search-and-create">
      <input
        v-model="searchTerm"
        placeholder="Sök pass"
        class="search-input"
        @input="filterSessions"
      >
      <select
        v-model="selectedSessionId"
        class="session-select"
        @change="selectSession"
      >
        <option value="">
          Välj pass
        </option>
        <option
          v-for="session in filteredSessions"
          :key="session.Id"
          :value="session.Id"
        >
          {{ formatDate(session.Date) }} - {{ session.Name }}
        </option>
      </select>
      <button
        class="create-button"
        @click="createNew"
      >
        Skapa nytt
      </button>
    </div>

    <!-- Loading and error messages -->
    <div
      v-if="isLoading"
      class="loading"
    >
      Loading sessions...
    </div>
    <div
      v-else-if="error"
      class="error"
    >
      {{ error }}
    </div>

    <!-- Sessions list -->
    <div
      v-else
      class="sessions-container"
    >
      <div
        v-if="filteredSessions.length"
        class="sessions-list"
      >
        <div
          v-for="session in filteredSessions"
          :key="session.Id"
          class="session-card"
          @click="selectSession(session.Id)"
        >
          <h2>{{ formatDate(session.Date) }} <i :class="sessionTypes[session.Type].icon" /></h2>
          <!-- Add UserAvatars component -->
          <UserAvatars 
            :user-ids="getSessionParticipants(session)" 
            :max-visible="5"
            :get-user-profile-picture="getUserProfilePicture"
            :user-names="userNames"
          />
          <!-- Add "Visa deltagare" button -->
          <v-btn
            v-if="isStaff"
            variant="text"
            size="small"
            color="primary"
            @click.stop="showParticipants(session)"
          >
            Visa deltagare
          </v-btn>
        </div>
      </div>
      <div v-else>
        No sessions found
      </div>
    </div>

    <!-- Add the new EditSessionModal component -->
    <EditSessionModal
      :show-modal="showModal"
      :current-session="currentSession"
      :session-types="sessionTypes"
      :record-type-options="recordTypeOptions"
      :trainers="trainers"
      :is-creating-new="isCreatingNew"
      :fetch-sessions="fetchSessions"
      :places="Object.values(places)"
      @update:show-modal="updateShowModal"
      @update:current-session="updateCurrentSession"
    />

    <!-- Participants Modal -->
    <v-dialog
      v-model="showParticipantsModal"
      max-width="500"
    >
      <v-card>
        <v-card-title>
          <span>Deltagare</span>
          <v-spacer />
          <v-btn
            icon
            @click="closeParticipantsModal"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-list v-if="selectedSession">
            <v-list-subheader>Bokade deltagare</v-list-subheader>
            <v-list-item
              v-for="userId in selectedSession.users"
              :key="userId"
            >
              <template #prepend>
                <v-avatar size="32">
                  <v-img
                    :src="getUserProfilePicture(userId)"
                    :alt="userNames[userId]"
                  />
                </v-avatar>
              </template>
              <v-list-item-title>{{ userNames[userId] || 'Laddar...' }}</v-list-item-title>
              <template #append>
                <v-btn
                  icon="mdi-delete"
                  size="small"
                  color="error"
                  @click="removeParticipant(userId, selectedSession.id)"
                />
              </template>
            </v-list-item>

            <v-divider />

            <v-list-subheader>Tillgängliga användare</v-list-subheader>
            <v-text-field
              v-model="userSearch"
              label="Sök användare"
              prepend-icon="mdi-magnify"
              @input="filterUsers"
            />
            <v-list-item
              v-for="user in filteredUsers"
              :key="user.id"
            >
              <template #prepend>
                <v-avatar size="32">
                  <v-img
                    :src="user.profilePicture"
                    :alt="user.fullName"
                  />
                </v-avatar>
              </template>
              <v-list-item-title>{{ user.fullName }}</v-list-item-title>
              <template #append>
                <v-btn
                  icon="mdi-plus"
                  size="small"
                  color="success"
                  @click="addParticipant(user.id)"
                />
              </template>
            </v-list-item>
          </v-list>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- Add this new dialog for displaying the analysis -->
    <v-dialog
      v-model="showAnalysisDialog"
      max-width="800px"
    >
      <v-card>
        <v-card-title>AI Analys av passet</v-card-title>
        <v-card-text>
          <div v-html="renderedAnalysis" />
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="primary"
            text
            @click="showAnalysisDialog = false"
          >
            Stäng
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>


<script>
// All imports should be at the very top
import { ref, onMounted, computed, watch, nextTick } from 'vue'
import { api } from '@/services/api.js'
import { debounce } from 'lodash'
import L from 'leaflet'
import 'leaflet/dist/leaflet.css'
import icon from 'leaflet/dist/images/marker-icon.png'
import iconShadow from 'leaflet/dist/images/marker-shadow.png'
import { useMainStore } from '@/stores'
import { parseISO, format, isWeekend, set } from 'date-fns'
import UserAvatars from '@/components/UserAvatars.vue'
import { storeToRefs } from 'pinia'
import { useUserState } from '@/composables/useUserState'
import { useToast } from 'vue-toast-notification'
import { VDialog, VCard, VBtn, VIcon, VCardText } from 'vuetify/components'
import { marked } from 'marked'
import DOMPurify from 'dompurify'
import EditSessionModal from '@/components/EditSessionModal.vue'

export default {
  name: 'EditSessionsPage',
  components: {
    UserAvatars,
    VDialog,
    VCard,
    VBtn,
    VIcon,
    VCardText,
    EditSessionModal,
  },
  setup() {
    const store = useMainStore()
    const { passes, userProfilePictures } = storeToRefs(store)
    const { getUserProfilePicture } = store
    const { isStaff } = useUserState()
    const toast = useToast()

    console.log('Passes from store:', passes.value)

    const currentUser = computed(() => {
      const user = store.user
      if (!user) {
        console.warn('User data not available in store')
        return null
      }
      return user
    })

    // Reactive state
    const currentSession = ref(null)
    const allSessions = ref([])
    const filteredSessions = ref([])
    const searchTerm = ref('')
    const selectedSessionId = ref('')
    const trainerSearchResults = ref([])
    const isEditing = computed(() => !!currentSession.value && !!currentSession.value.id)
    const showModal = ref(false)

    const exercises = ref([])
    const places = ref({})
    const trainers = ref([])

    const mapContainer = ref(null)
    const mapInstance = ref(null)
    const totalDistance = ref(0)
    const totalAscent = ref(0)
    const totalDescent = ref(0)

    // Add this function to setup Leaflet icon
    const setupLeafletIcon = () => {
      delete L.Icon.Default.prototype._getIconUrl
      L.Icon.Default.mergeOptions({
        iconRetinaUrl: `${window.location.origin}${icon}`,
        iconUrl: `${window.location.origin}${icon}`,
        shadowUrl: `${window.location.origin}${iconShadow}`,
      })
    }

    // Initialize the map
    const initMap = async () => {
      await nextTick()

      if (mapContainer.value && mapContainer.value.offsetParent !== null) {
        console.log('Map container dimensions:', mapContainer.value.offsetWidth, mapContainer.value.offsetHeight)
        if (mapInstance.value) {
          mapInstance.value.remove()
        }
        setupLeafletIcon()
        mapInstance.value = L.map(mapContainer.value, {
          center: [58.93208, 17.57791],
          zoom: 13,
          zoomControl: false
        })
        L.control.zoom({ position: 'topright' }).addTo(mapInstance.value)
        L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
          attribution: '© OpenStreetMap contributors'
        }).addTo(mapInstance.value)

        // Force a resize after initialization
        setTimeout(() => {
          mapInstance.value.invalidateSize()
        }, 100)
      }
    }

    // Update the map based on currentSession
    const updateMap = async () => {
      if (!currentSession.value || !mapInstance.value) {
        console.warn('Map not initialized or no current session')
        return
      }

      console.log('Map instance:', mapInstance.value)

      // Ensure the map container is visible before updating
      if (mapContainer.value && mapContainer.value.offsetParent === null) {
        await nextTick()
        await initMap()
      }

      try {
        // Clear existing layers
        mapInstance.value.eachLayer((layer) => {
          if (layer instanceof L.Marker || layer instanceof L.Polyline) {
            mapInstance.value.removeLayer(layer)
          }
        })

        const latlngs = getSessionLatLngs(currentSession.value)
        const markers = latlngs.map(([lat, lng]) => {
          const marker = L.marker([lat, lng]).addTo(mapInstance.value)
          const placeData = places.value.find(p => p.location && p.location.coordinates && p.location.coordinates[0] === lng && p.location.coordinates[1] === lat)
          if (placeData) {
            const record = currentSession.value.segments.flatMap(s => s.records).find(r => r.placeId === placeData.id)
            marker.bindPopup(`<b>${placeData.name}</b><br>${record ? (record.exerciseName || record.placeName) : ''}`)
          }
          return marker
        })

        if (latlngs.length > 0) {
          const polyline = L.polyline(latlngs, { color: 'red' }).addTo(mapInstance.value)
          mapInstance.value.fitBounds(polyline.getBounds(), { padding: [50, 50] })
        } else if (markers.length > 0) {
          const group = L.featureGroup(markers)
          mapInstance.value.fitBounds(group.getBounds(), { padding: [50, 50] })
        } else {
          mapInstance.value.setView([58.93208, 17.57791], 13)
        }

        calculateDistanceAndElevation(latlngs)

        // Force a resize after updating
        mapInstance.value.invalidateSize()

        console.log('Map updated with', markers.length, 'markers and', latlngs.length, 'points')
      } catch (error) {
        console.error('Error updating map:', error)
      }
    }

    // Helper functions
    const isValidCoordinate = (lat, lng) => {
      return lat !== undefined && lng !== undefined &&
             !isNaN(lat) && !isNaN(lng) &&
             Math.abs(lat) <= 90 && Math.abs(lng) <= 180
    }

    const calculateDistanceAndElevation = (coordinates) => {
      totalDistance.value = 0
      totalAscent.value = 0
      totalDescent.value = 0

      for (let i = 0; i < coordinates.length - 1; i++) {
        const point1 = coordinates[i]
        const point2 = coordinates[i + 1]

        totalDistance.value += calculateHaversineDistance(point1, point2)
        // Placeholder for elevation calculations
        // Implement elevation data fetching if available
      }
    }

    const calculateHaversineDistance = (point1, point2) => {
      const R = 6371e3 // Earth's radius in meters
      const φ1 = point1[0] * Math.PI / 180
      const φ2 = point2[0] * Math.PI / 180
      const Δφ = (point2[0] - point1[0]) * Math.PI / 180
      const Δλ = (point2[1] - point1[1]) * Math.PI / 180

      const a = Math.sin(Δφ / 2) * Math.sin(Δφ / 2) +
                Math.cos(φ1) * Math.cos(φ2) *
                Math.sin(Δλ / 2) * Math.sin(Δλ / 2)
      const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))

      return R * c
    }

    // Watchers
    watch(currentSession, async () => {
      if (currentSession.value) {
        await nextTick()
        initMap()
        updateMap()
      }
    }, { deep: true })

    watch(showModal, async (newValue) => {
      if (newValue) {
        await nextTick()
        initMap()
        updateMap()
      }
    })

    // Define sessionTypes only once
    const sessionTypes = ref({
      0: { name: 'Slinga', icon: 'fad fa-route' },
      1: { name: 'Stege', icon: 'fad fa-stairs' },
      2: { name: 'Pyramid', icon: 'fad fa-triangle' },
      3: { name: 'Tabata', icon: 'fad fa-stopwatch' },
      4: { name: 'AMRAP', icon: 'fad fa-infinity' },
      5: { name: 'EMOM', icon: 'fad fa-clock' },
      6: { name: 'På tid', icon: 'fad fa-hourglass-half' },
      7: { name: 'Chipper', icon: 'fad fa-list-ol' },
      8: { name: 'Hero WOD', icon: 'fad fa-medal' },
      9: { name: 'Styrka', icon: 'fad fa-dumbbell' },
      10: { name: 'Teknik', icon: 'fad fa-bullseye-arrow' },
      11: { name: 'Cirkelträning', icon: 'fad fa-circle-notch' },
      12: { name: 'Intervalltrning', icon: 'fad fa-wave-square' }
    })

    const recordTypeOptions = ref({
      0: { name: 'Övning', icon: 'fad fa-dumbbell' },
      2: { name: 'Hinder', icon: 'fad fa-mountain' },
      1: { name: 'Vila', icon: 'fad fa-bed' },
      4: { name: 'Tidsbaserad', icon: 'fad fa-stopwatch' },
      3: { name: 'Teknik', icon: 'fad fa-graduation-cap' }
    })

    // Modify the fetchSessions function to include error handling and loading state
    const isLoading = ref(false)
    const error = ref(null)

    const userNames = ref({})

    const fetchUserNames = async (userIds) => {
      for (const userId of userIds) {
        if (!userNames.value[userId]) {
          try {
            const user = await store.getUserById(userId)
            userNames.value[userId] = `${user.firstName} ${user.lastName}`
          } catch (error) {
            console.error(`Error fetching user name for user ${userId}:`, error)
            userNames.value[userId] = 'Unknown User'
          }
        }
      }
    }

    const getSessionParticipants = (session) => {
      console.log('Getting participants for session:', session.Id)
      // Use the Participants property directly from the session object
      const participants = session.Participants || []
      console.log('Participants found:', participants)
      return participants
    }

    // Modify the fetchSessions function to fetch user names
    const fetchSessions = async () => {
      console.log('Fetching sessions...')
      isLoading.value = true
      error.value = null
      try {
        const response = await api.getSessions()
        console.log('Sessions fetched:', response.data)
        
        // Log dates before sorting
        console.log('Dates before sorting:', response.data.map(s => s.Date))
        
        allSessions.value = response.data.sort((a, b) => {
          const dateA = parseISO(a.Date)
          const dateB = parseISO(b.Date)
          console.log(`Comparing: ${a.Date} (${dateA}) and ${b.Date} (${dateB})`)
          return dateB.getTime() - dateA.getTime()
        })
        
        // Log dates after sorting
        console.log('Dates after sorting:', allSessions.value.map(s => s.Date))
        
        filteredSessions.value = [...allSessions.value]
        
        // After fetching sessions, get all participant IDs and fetch their names
        const allParticipantIds = allSessions.value.flatMap(session => session.Participants || [])
        await fetchUserNames(allParticipantIds)
      } catch (err) {
        console.error('Error fetching sessions:', err)
        error.value = 'Failed to fetch sessions. Please try again.'
      } finally {
        isLoading.value = false
      }
    }

    // Update the filterSessions function
    const filterSessions = () => {
      filteredSessions.value = allSessions.value
        .filter(session => 
          searchTerm.value === '' || // Include all sessions if search term is empty
          session.Name.toLowerCase().includes(searchTerm.value.toLowerCase()) ||
          formatDate(session.Date).toLowerCase().includes(searchTerm.value.toLowerCase())
        )
        // No need to sort here as allSessions is already sorted
    }

    // Add this helper function to get user information
    const getUserById = async (userId) => {
      try {
        return await store.getUserById(userId)
      } catch (error) {
        console.error(`Error fetching user with id ${userId}:`, error)
        return null
      }
    }

    // Update the selectSession function
    const selectSession = async (id) => {
      if (!id) {
        console.error('Invalid session id')
        return
      }
      selectedSessionId.value = id
      const selectedSession = allSessions.value.find(s => s.Id === id)
      if (selectedSession) {
        console.log('Selected session:', JSON.stringify(selectedSession, null, 2))
        
        // Fetch all exercises and places if not already loaded
        if (exercises.value.length === 0) {
          await fetchExercises()
        }
        if (places.value.length === 0) {
          await fetchPlaces()
        }
        console.log('Places data:', places.value);
        
        currentSession.value = {
          id: selectedSession.Id,
          date: selectedSession.Date,
          name: selectedSession.Name || '',
          type: selectedSession.Type,
          description: selectedSession.Description || '',
          trainer: selectedSession.Trainer, // This should now be the trainer's ID
          segments: Array.isArray(selectedSession.Segments) 
            ? selectedSession.Segments.map(segment => ({
                id: segment.Id || Date.now() + Math.random(),
                label: segment.Label,
                type: segment.Type,
                records: Array.isArray(segment.Records)
                  ? segment.Records.map(record => ({
                      id: record.Id || Date.now() + Math.random(),
                      recordType: record.RecordType,
                      exerciseId: record.ExerciseId,
                      exerciseName: getExerciseName(record.ExerciseId),
                      repetitions: record.Repetitions,
                      description: record.Description || '',
                      placeId: record.PlaceId || '',
                      placeName: getPlaceName(record.PlaceId),
                      duration: record.Duration,
                      unit: record.Unit || '',
                      exerciseSearchResults: [],
                      placeSearchResults: []
                    }))
                  : []
              }))
            : [],
          metadata: selectedSession.Metadata,
          ratings: selectedSession.Ratings || [],
          weather: selectedSession.Weather || [],
          imageUrl: selectedSession.ImageUrl || [],
          distance: selectedSession.Distance,
          elevation: selectedSession.Elevation,
          cal: selectedSession.Cal,
          participants: selectedSession.Participants || []
        }
        
        // Set the trainer search input
        const trainerUser = await getUserById(currentSession.value.trainer)
        trainerSearchInput.value = trainerUser ? `${trainerUser.firstName} ${trainerUser.lastName}` : ''
        
        console.log('Processed current session:', JSON.stringify(currentSession.value, null, 2))
        openModal()

        // Update the map and calculate distance after opening the modal
        nextTick(async () => {
          await initMap()
          await updateMap()
          
          // Force recalculation of distance and elevation
          const latlngs = getSessionLatLngs(currentSession.value)
          calculateDistanceAndElevation(latlngs)
          
          // Update the currentSession with the new calculations
          currentSession.value = {
            ...currentSession.value,
            distance: totalDistance.value,
            elevation: totalAscent.value
          }
        })
      } else {
        console.error(`Session with id ${id} not found`)
      }
    }

    // Add this new helper function to get latlngs from the session
    const getSessionLatLngs = (session) => {
      const latlngs = []
      for (const segment of session.segments) {
        for (const record of segment.records) {
          if ((record.recordType === 0 || record.recordType === 2) && record.placeId) {
            const placeData = places.value.find(p => p.id === record.placeId)
            if (placeData?.location?.coordinates) {
              const [lng, lat] = placeData.location.coordinates
              if (isValidCoordinate(lat, lng)) {
                latlngs.push([lat, lng])
              }
            }
          }
        }
      }
      return latlngs
    }

    // Update the createNew function
    const createNew = () => {
      console.log('Creating new session, current user:', currentUser.value)
      const currentDate = new Date()
      const formattedDate = format(currentDate, "yyyy-MM-dd'T'HH:mm:ss")
      currentSession.value = {
        id: null, // Set to null for new sessions
        date: formattedDate,
        name: `Pass ${format(currentDate, 'yyyy-MM-dd')}`,
        trainer: currentUser.value ? currentUser.value.id : null,
        type: 0, // Set a default type
        segments: [
          {
            id: Date.now(),
            label: 'Slinga',
            type: 0,
            records: [
              {
                id: Date.now() + 1,
                recordType: 0,
                exerciseId: null,
                exerciseName: '',
                repetitions: '',
                description: '',
                placeId: '',
                placeName: '',
                duration: '',
                unit: '',
                exerciseSearchResults: [],
                placeSearchResults: []
              }
            ]
          }
        ],
        description: '',
        metadata: {},
        ratings: [],
        weather: [],
        imageUrl: [],
        distance: 0,
        elevation: 0,
        cal: 0,
        participants: []
      }
      selectedTrainer.value = currentUser.value ? {
        id: currentUser.value.id,
        name: `${currentUser.value.firstName} ${currentUser.value.lastName}`
      } : null
      console.log('New session created:', currentSession.value)
      selectedSessionId.value = ''
      showModal.value = true
    }

    // Save session (create or update)
    const saveSession = async () => {
      try {
        console.log('Current session before saving:', JSON.stringify(currentSession.value, null, 2));

        if (!currentSession.value.date) {
          console.error('Date is missing from the current session');
          toast.error('Vänligen ange ett datum för passet.');
          return;
        }

        const sessionData = {
          id: currentSession.value.id,
          name: currentSession.value.name || `Session on ${formatDate(currentSession.value.date)}`,
          date: currentSession.value.date,
          type: currentSession.value.type,
          description: currentSession.value.description || '',
          trainer: currentSession.value.trainer, // This should now be the trainer's ID
          segments: currentSession.value.segments.map(segment => ({
            label: segment.label,
            type: segment.type,
            records: segment.records.map(record => {
              const transformedRecord = {
                recordType: record.recordType,
                placeId: record.placeId || ''
              }

              if (record.recordType === 0) {
                transformedRecord.exerciseId = record.exerciseId
                transformedRecord.exerciseName = record.exerciseName
                transformedRecord.repetitions = record.repetitions
                transformedRecord.description = record.description || ''
              } else if (record.recordType === 2) {
                transformedRecord.placeName = record.placeName
              }

              if (record.recordType === 1 || record.recordType === 4) {
                transformedRecord.duration = record.duration
                transformedRecord.unit = record.unit
              }

              return transformedRecord
            })
          })),
          metadata: currentSession.value.metadata,
          ratings: currentSession.value.ratings || [],
          weather: currentSession.value.weather || [],
          imageUrl: currentSession.value.imageUrl || [],
          distance: currentSession.value.distance,
          elevation: currentSession.value.elevation,
          cal: currentSession.value.cal,
          participants: currentSession.value.participants || [],
        }

        console.log('Saving session with transformed data:', JSON.stringify(sessionData, null, 2))

        let response
        if (isEditing.value) {
          console.log(`Updating session with ID: ${sessionData.id}`)
          response = await api.updateSession(sessionData.id, sessionData)
        } else {
          console.log('Creating new session')
          response = await api.createSession(sessionData)
        }

        console.log('API response:', response)

        toast.success('Passet har sparats!')
        await fetchSessions() // Refresh the list
        closeModal() // Close the modal and clear the form
      } catch (error) {
        console.error('Error saving session:', error)
        if (error.response) {
          console.error('Error response:', error.response.data)
        }
        toast.error('Kunde inte spara passet. Försök igen.')
      }
    }

    // Cancel editing/creating session
    const cancel = () => {
      currentSession.value = null
      selectedSessionId.value = ''
    }

    // Add a new segment
    const addSegment = () => {
      if (!currentSession.value) {
        console.error('No current session available')
        return
      }
      
      const newSegment = {
        id: Date.now(),
        label: `Slinga ${currentSession.value.segments.length + 1}`, // Updated label
        type: '0', // Default to Trail
        records: [
          {
            id: Date.now() + 1,
            recordType: 0,
            exerciseId: null,
            exerciseName: '',
            repetitions: '',
            description: '',
            placeId: '',
            placeName: '',
            duration: '',
            unit: '',
            exerciseSearchResults: [],
            placeSearchResults: []
          }
        ]
      }
      
      updateSegmentFields(newSegment);
      currentSession.value.segments.push(newSegment)
    }

    // Remove a segment
    const removeSegment = (id) => {
      currentSession.value.segments = currentSession.value.segments.filter(segment => segment.id !== id)
    }

    // Add a new record to a segment
    const addRecord = (segmentId, recordType = 0) => {
      const segment = currentSession.value.segments.find(s => s.id === segmentId)
      if (segment) {
        const newRecord = {
          id: Date.now(),
          recordType: recordType,
          placeId: '',
          placeName: '',
          placeSearchResults: []
        }

        if (recordType === 0) {
          newRecord.exerciseId = null
          newRecord.exerciseName = ''
          newRecord.repetitions = ''
          newRecord.description = ''
          newRecord.exerciseSearchResults = []
        } else if (recordType === 1 || recordType === 4) {
          newRecord.duration = ''
          newRecord.unit = ''
        }

        segment.records.push(newRecord)
      }
    }

    // Remove a record from a segment
    const removeRecord = (segmentId, recordId) => {
      const segment = currentSession.value.segments.find(s => s.id === segmentId)
      if (segment) {
        segment.records = segment.records.filter(r => r.id !== recordId)
      }
    }

    // Add this to your reactive state declarations
    const trainerSearchInput = ref('')

    // Update the searchTrainers function
    const searchTrainers = debounce(async () => {
      console.log('Searching trainers with term:', trainerSearchInput.value)
      if (trainerSearchInput.value.length > 1) {
        try {
          const response = await api.getUsers()
          console.log('API response:', response)
          const filteredUsers = response.data.filter(user => 
            `${user.firstName} ${user.lastName}`.toLowerCase().includes(trainerSearchInput.value.toLowerCase())
          )
          console.log('Filtered users:', filteredUsers)
          
          trainerSearchResults.value = filteredUsers
          console.log('Trainer search results:', trainerSearchResults.value)
        } catch (error) {
          console.error('Error searching trainers:', error)
          trainerSearchResults.value = []
        }
      } else {
        trainerSearchResults.value = []
      }
    }, 300)

    // Update the selectTrainer function
    const selectTrainer = (trainer) => {
      console.log('Selecting trainer:', trainer);
      if (currentSession.value) {
        currentSession.value.trainer = trainer.id; // Save the ID directly
        trainerSearchInput.value = `${trainer.firstName} ${trainer.lastName}`; // Keep the name for display
      }
      trainerSearchResults.value = [];
      console.log('Updated currentSession:', JSON.stringify(currentSession.value, null, 2));
    }

    // Fetch all exercises
    const fetchExercises = async () => {
      try {
        const response = await api.getExercises()
        exercises.value = response.data
      } catch (error) {
        console.error('Error fetching exercises:', error)
      }
    }

    // Update the searchExercises function
    const searchExercises = debounce((recordId) => {
      const record = findRecordById(recordId)
      if (record && record.exerciseName.length > 2) {
        const allExercises = exercises.value
        record.exerciseSearchResults = allExercises.filter(result => 
          result.name.toLowerCase().includes(record.exerciseName.toLowerCase())
        ).slice(0, 10) // Limit to 10 results for better performance
      } else if (record) {
        record.exerciseSearchResults = []
      }
    }, 300)

    // Add a new function to select an exercise
    const selectExercise = (exercise, record) => {
      record.exerciseId = exercise.id
      record.exerciseName = exercise.name
      record.exerciseSearchResults = []
    }

    // Fetch all places
    const fetchPlaces = async () => {
      try {
        const response = await api.getPlaces()
        places.value = Array.isArray(response.data) ? response.data : []
      } catch (error) {
        console.error('Error fetching places:', error)
      }
    }

    // Update the searchPlaces function
    const searchPlaces = debounce((recordId) => {
      const record = findRecordById(recordId)
      if (record && record.placeName.length > 2) {
        const allPlaces = places.value
        record.placeSearchResults = allPlaces.filter(result => 
          result.name.toLowerCase().includes(record.placeName.toLowerCase())
        ).slice(0, 10) // Limit to 10 results for better performance
      } else if (record) {
        record.placeSearchResults = []
      }
    }, 300)

    // Update the selectPlace function
    const selectPlace = (place, record) => {
      console.log('Selecting place:', place)
      record.placeId = place.id
      record.placeName = place.name
      record.placeSearchResults = []
      updateMap()
    }

    // Helper to find a record by ID
    const findRecordById = (recordId) => {
      for (const segment of currentSession.value.segments) {
        const record = segment.records.find(r => r.id === recordId)
        if (record) return record
      }
      return null
    }

    // Get exercise name by ID
    const getExerciseName = (exerciseId) => {
      const exercise = exercises.value.find(e => e.id === exerciseId)
      return exercise ? exercise.name : ''
    }

    // Get place name by ID
    // eslint-disable-next-line no-unused-vars
    const getPlaceName = (placeId) => {
      const place = places.value.find(p => p.id === placeId)
      return place ? place.name : ''
    }

    // Capitalize first letter utility
    const capitalize = (s) => {
      if (typeof s !== 'string') return ''
      return s.charAt(0).toUpperCase() + s.slice(1)
    }

    // Format date for display
    const formatDate = (dateString) => {
      const options = { year: 'numeric', month: 'long', day: 'numeric', hour: '2-digit', minute: '2-digit' }
      return new Date(dateString).toLocaleDateString('sv-SE', options)
    }

    // Update the getSegmentCount function
    const getSegmentCount = (session) => {
      return session.Segments ? session.Segments.length : 0
    }

    // Open the modal
    const openModal = () => {
      showModal.value = true
    }

    // Close the modal
    const closeModal = () => {
      showModal.value = false
      currentSession.value = null
      selectedSessionId.value = ''
    }


    // Update map when currentSession changes
    watch(currentSession, async () => {
      if (currentSession.value) {
        nextTick(() => {
          initMap()
          updateMap()
        })
      }
    }, { deep: true })

    // Update map when modal is opened
    watch(showModal, async (newValue) => {
      if (newValue) {
        nextTick(() => {
          initMap()
          updateMap()
        })
      }
    })

    // Fetch initial data on mount
    onMounted(async () => {
      console.log('EditSessionsPage mounted')
      if (!store.user) {
        console.log('User data not available, fetching...')
        await store.fetchCurrentUser()
      }
      await fetchSessions()
      await fetchExercises()
      await fetchPlaces()
      await fetchTrainers() // Make sure this line is included
      setupLeafletIcon()
      nextTick(() => {
        initMap()
      })
    })

    const drag = ref(false)

    // Add this near the other watch statements
    watch(currentUser, (newValue) => {
      console.log('Current user updated:', newValue)
    }, { immediate: true })

    watch(() => store.user, (newUser) => {
      console.log('Store user state changed:', newUser)
      if (newUser && !newUser.error) {
        // Update any local state or perform actions when user data becomes available
      }
    })

    const formattedDate = computed({
      get: () => {
        if (!currentSession.value || !currentSession.value.date) return ''
        return format(parseISO(currentSession.value.date), 'yyyy-MM-dd')
      },
      set: (newValue) => {
        if (currentSession.value) {
          const newDate = parseISO(newValue)
          currentSession.value.date = format(newDate, "yyyy-MM-dd'T'HH:mm:ss")
          updateSessionTime(newDate)
        }
      }
    })

    const formattedTime = computed({
      get: () => {
        if (!currentSession.value || !currentSession.value.date) return ''
        return format(parseISO(currentSession.value.date), 'HH:mm')
      },
      set: (newValue) => {
        if (currentSession.value) {
          const [hours, minutes] = newValue.split(':')
          const newDate = set(parseISO(currentSession.value.date), { hours, minutes })
          currentSession.value.date = format(newDate, "yyyy-MM-dd'T'HH:mm:ss")
        }
      }
    })

    const updateSessionTime = (date) => {
      if (!date) return
      const defaultTime = isWeekend(date) ? { hours: 10, minutes: 0 } : { hours: 18, minutes: 0 }
      const newDate = set(date, defaultTime)
      currentSession.value.date = format(newDate, "yyyy-MM-dd'T'HH:mm:ss")
      currentSession.value.name = `Pass ${format(newDate, 'yyyy-MM-dd')}` // Update the name
    }

    // Add these new refs
    const showParticipantsModal = ref(false)
    const selectedSession = ref(null)
    const availableUsers = ref([])
    const userSearch = ref('')
    const filteredUsers = ref([])

    const showParticipants = async (session) => {
      selectedSession.value = {
        ...session,
        users: session.participants || [],
      }
      console.log('Selected session:', selectedSession.value)
      showParticipantsModal.value = true
      await fetchUserProfilePictures()
      await fetchUserNames(selectedSession.value.users)
      await fetchAvailableUsers()
    }

    const closeParticipantsModal = () => {
      showParticipantsModal.value = false
      selectedSession.value = null
      userSearch.value = ''
    }

    const fetchAvailableUsers = async () => {
      try {
        const response = await api.getUsers()
        if (Array.isArray(response.data)) {
          availableUsers.value = response.data
            .filter((availableUser) => !selectedSession.value.users.includes(availableUser.id))
            .map((availableUser) => ({
              ...availableUser,
              profilePicture: availableUser.profilePicture ? getUserProfilePicture(availableUser.id) : null,
              fullName: `${availableUser.firstName} ${availableUser.lastName}`,
            }))
        } else {
          console.error('Unexpected response format from getUsers:', response)
          availableUsers.value = []
        }
        filteredUsers.value = availableUsers.value
      } catch (error) {
        console.error('Error fetching users:', error)
      }
    }

    const fetchUserProfilePictures = async () => {
      if (selectedSession.value && Array.isArray(selectedSession.value.users)) {
        for (const userId of selectedSession.value.users) {
          if (!userProfilePictures.value[userId]) {
            try {
              const response = await api.getUserById(userId)
              const user = response.data
              if (user) {
                userProfilePictures.value[userId] = getUserProfilePicture(userId)
                userNames.value[userId] = `${user.firstName} ${user.lastName}`
              } else {
                console.error(`User data not found for userId: ${userId}`)
                userProfilePictures.value[userId] = require('@/assets/default-profile-picture.png')
                userNames.value[userId] = 'Unknown User'
              }
            } catch (error) {
              console.error(`Error fetching profile picture for user ${userId}:`, error)
              userProfilePictures.value[userId] = require('@/assets/default-profile-picture.png')
              userNames.value[userId] = 'Unknown User'
            }
          }
        }
      }
    }

    const filterUsers = () => {
      const searchTerm = userSearch.value.toLowerCase()
      filteredUsers.value = availableUsers.value.filter(
        (user) =>
          user.firstName.toLowerCase().includes(searchTerm) ||
          user.lastName.toLowerCase().includes(searchTerm)
      )
    }

    const addParticipant = async (userId) => {
      try {
        await api.createBooking(selectedSession.value.id, userId)
        const addedUser = availableUsers.value.find((user) => user.id === userId)
        if (addedUser) {
          selectedSession.value.users.push(userId)
          userNames.value[userId] = `${addedUser.firstName} ${addedUser.lastName}`
          userProfilePictures.value[userId] =
            addedUser.profilePicture || require('@/assets/default-profile-picture.png')
        }
        await fetchAvailableUsers()
        toast.success('Deltagare tillagd framgångsrikt.')
      } catch (error) {
        console.error('Error adding participant:', error)
        toast.error('Det gick inte att lägga till deltagaren. Försök igen.')
      }
    }

    const removeParticipant = async (userId, sessionId) => {
      try {
        await api.deleteBooking(sessionId, userId)
        selectedSession.value.users = selectedSession.value.users.filter(id => id !== userId)
        // Update the session in allSessions.value
        const sessionIndex = allSessions.value.findIndex(s => s.id === sessionId)
        if (sessionIndex !== -1) {
          allSessions.value[sessionIndex].participants = allSessions.value[sessionIndex].participants.filter(id => id !== userId)
        }
        await fetchAvailableUsers()
        toast.success('Deltagare borttagen framgångsrikt.')
      } catch (error) {
        console.error('Error removing participant:', error)
        toast.error('Det gick inte att ta bort deltagaren. Försök igen.')
      }
    }

    const handleSessionEnhanced = (enhancedData) => {
      if (enhancedData && enhancedData.enhancedSegments) {
        // Preserve existing segment IDs where possible and ensure records are properly added
        const updatedSegments = enhancedData.enhancedSegments.map((enhancedSegment, index) => {
          const existingSegment = currentSession.value.segments[index];
          return {
            ...enhancedSegment,
            id: existingSegment ? existingSegment.id : `new-segment-${Date.now()}-${index}`,
            records: enhancedSegment.records.map((record, recordIndex) => ({
              ...record,
              id: `new-record-${Date.now()}-${index}-${recordIndex}`,
              exerciseSearchResults: [],
              placeSearchResults: []
            }))
          };
        });

        // Update the currentSession with the new segments
        currentSession.value = {
          ...currentSession.value,
          segments: updatedSegments
        };

        toast.success('Passet har förbättrats med AI!');
        console.log('AI förklaring:', enhancedData.explanation);
        console.log('Updated session:', JSON.stringify(currentSession.value, null, 2));
        
        // Force a re-render of the component
        nextTick(() => {
          currentSession.value = { ...currentSession.value };
        });
        
        // Update the map if necessary
        nextTick(() => {
          updateMap();
        });
      } else {
        console.error('Invalid enhanced data received:', enhancedData);
        toast.error('Kunde inte förbättra passet. Ogiltig data mottagen.');
      }
    };

    const handleEnhanceError = (errorMessage) => {
      toast.error(errorMessage);
    };

    // Add this function to handle segment type changes
    const updateSegmentFields = (segment) => {
      // Reset all fields
      segment.startReps = null;
      segment.endReps = null;
      segment.step = null;
      segment.workTime = null;
      segment.restTime = null;
      segment.rounds = null;
      segment.duration = null;
      segment.intervalTime = null;
      segment.totalTime = null;

      // Set default values based on segment type
      switch (segment.type) {
        case 'ladder':
        case 'pyramid':
          segment.startReps = 1;
          segment.endReps = 10;
          segment.step = 1;
          break;
        case 'tabata':
          segment.workTime = 20;
          segment.restTime = 10;
          segment.rounds = 8;
          break;
        case 'amrap':
        case 'for_time':
          segment.duration = 20;
          break;
        case 'emom':
          segment.intervalTime = 60;
          segment.totalTime = 10;
          break;
      }
    }

    const showAnalysisDialog = ref(false);
    const analysisResult = ref('');
    const renderedAnalysis = computed(() => {
      return DOMPurify.sanitize(marked(analysisResult.value));
    });

    const handleSessionAnalyzed = (analysis) => {
      analysisResult.value = analysis;
      showAnalysisDialog.value = true;
    };

    const handleAnalyzeError = (errorMessage) => {
      toast.error(errorMessage);
    };

    const selectedTrainer = ref(null)

    const formattedTrainers = computed(() => {
      return trainers.value.map(trainer => ({
        id: trainer.id,
        name: `${trainer.firstName} ${trainer.lastName}`
      }))
    })

    const updateTrainer = (trainer) => {
      if (trainer) {
        currentSession.value.trainer = trainer.id
        currentSession.value.trainerName = trainer.name
      } else {
        currentSession.value.trainer = null
        currentSession.value.trainerName = ''
      }
    }

    // Watch for changes in currentSession.trainer
    watch(() => currentSession.value.trainer, (newTrainerId) => {
      if (newTrainerId) {
        const trainer = formattedTrainers.value.find(t => t.id === newTrainerId)
        if (trainer) {
          selectedTrainer.value = trainer
        }
      } else {
        selectedTrainer.value = null
      }
    }, { immediate: true })

    const fetchTrainers = async () => {
      try {
        const response = await api.getUsers()
        trainers.value = response.data
        console.log('Fetched users:', trainers.value)
      } catch (error) {
        console.error('Error fetching users:', error)
        toast.error('Failed to fetch users. Please try again.')
      }
    }

    const updateCurrentSession = (updatedSession) => {
      currentSession.value = updatedSession
    }

    const updateShowModal = (value) => {
      showModal.value = value
      if (!value) {
        // Reset currentSession when closing the modal
        currentSession.value = null
      }
    }

    // Add a new computed property to check if we're creating a new session
    const isCreatingNew = computed(() => currentSession.value && !currentSession.value.id)

    // Update the watch for currentSession
    watch(() => currentSession.value, (newSession) => {
      if (newSession) {
        console.log('Current session updated:', newSession)
        nextTick(() => {
          initMap()
          updateMap()
        })
      }
    }, { deep: true, immediate: true })

    return {
      currentSession,
      isEditing,
      trainerSearchResults,
      saveSession,
      cancel,
      addSegment,
      removeSegment,
      addRecord,
      removeRecord,
      searchTrainers,
      selectTrainer,
      searchExercises,
      selectExercise,
      searchPlaces,
      selectPlace,
      allSessions,
      filteredSessions,
      searchTerm,
      selectedSessionId,
      filterSessions,
      selectSession,
      createNew,
      formatDate,
      getSegmentCount,
      showModal,
      openModal,
      closeModal,
      exercises,
      places,
      mapContainer,
      totalDistance,
      totalAscent,
      totalDescent,
      sessionTypes,
      capitalize,
      getExerciseName,
      isLoading,
      error,
      drag,
      recordTypeOptions,
      currentUser, // Add this to the returned object
      formattedDate,
      trainerSearchInput,
      getSessionParticipants,
      getUserProfilePicture,
      userNames,
      isStaff,
      showParticipantsModal,
      selectedSession,
      availableUsers,
      userSearch,
      filteredUsers,
      showParticipants,
      closeParticipantsModal,
      addParticipant,
      removeParticipant,
      filterUsers,
      handleSessionEnhanced,
      handleEnhanceError,
      formattedTime,
      updateSessionTime,
      getUserById, // Add this to the returned object if you need to use it elsewhere
      updateSegmentFields,
      showAnalysisDialog,
      renderedAnalysis,
      handleSessionAnalyzed,
      handleAnalyzeError,
      trainers, // Add this line to expose trainers to the template
      selectedTrainer,
      formattedTrainers,
      updateTrainer,
      fetchSessions, // Add this line to include fetchSessions in the returned object
      fetchTrainers,
      updateCurrentSession,
      updateShowModal,
      isCreatingNew,
    }
  }
}
</script>

<style scoped>
.edit-sessions-page {
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
}

.search-and-create {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: 20px;
}

.search-input,
.session-select,
.create-button {
  flex: 1;
  min-width: 200px;
  padding: 10px;
  font-size: 16px;
}

.sessions-container {
  margin-bottom: 20px;
}

.sessions-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 20px;
}

.session-card {
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 15px;
  cursor: pointer;
  transition: box-shadow 0.3s ease;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.session-card:hover {
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
}

/* Update these styles for the new modal structure */
.edit-modal {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.edit-modal-content {
  flex-grow: 1;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  margin: 0px;
}



/* Ensure the dialog takes up the full screen on mobile */
@media (max-width: 600px) {
  .v-dialog {
    margin: 0 !important;
    position: fixed !important;
    top: 0 !important;
    left: 0 !important;
    width: 100% !important;
    height: 100% !important;
    max-width: none !important;
  }
}

.edit-form {
  display: flex;
  flex-direction: column;
  gap: 20px;
  max-width: 100%;
  overflow-x: auto;
}

.session-details {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.date-time-container {
  display: flex;
  gap: 15px;
}

.date-group,
.time-group {
  flex: 1;
  max-width: calc(50% - 7.5px); /* Subtracting half of the gap */
}

.form-group {
  display: flex;
  flex-direction: column;
}

.form-group label {
  margin-bottom: 5px;
}

.form-group input {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ddd;
  border-radius: 5px;
}

.segments-container,
.records-container {
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 15px;
}

.segments-list,
.records-list {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.segment,
.record {
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 10px;
  min-width: 1300px;
}

.segment-header,
.record-header {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 10px;
}

.segment-drag-handle,
.record-drag-handle {
  cursor: move;
  padding: 5px;
  color: #999;
}


.segment-type,
.record-type {
  flex: 1;
  min-width: 0;
  max-width: 60px;
}
.segment-label{
  flex: 1;
  min-width: 0;
  max-width: 100%;
}

.record-details {
  display: flex;
  flex-grow: 1;
  gap: 10px;
  align-items: center;
}

.exercise-search,
.place-search {
  flex-grow: 1;
  position: relative;
}

.repetitions-input {
  width: 80px; /* Adjusted to be smaller */
}

.description-input {
  flex-grow: 1;
}

.remove-button,
.add-button {
  padding: 5px 10px;
  font-size: 14px;
  cursor: pointer;
}

.remove-button {
  background: none;
  border: none;
  color: #ff4d4d;
}

.add-button {
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 5px;
}

.map-container {
  width: 100%;
  height: 400px; /* Adjust this value as needed */
  margin-bottom: 20px;
}

.session-map {
  width: 100%;
  height: 100%;
}

.session-stats {
  background-color: #f0f0f0;
  border-radius: 5px;
  padding: 10px;
}

.save-button,
.cancel-button {
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
}

.save-button {
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 5px;
}

.cancel-button {
  background-color: #f44336;
  color: white;
  border: none;
  border-radius: 5px;
  margin-left: 10px;
}

@media (max-width: 768px) {
  :deep(.modal-container) {
    width: 95% !important;
    max-width: none !important;
  }
}

/* Add these new styles for search results */
.search-results {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background-color: white;
  border: 1px solid #ccc;
  border-top: none;
  max-height: 200px;
  overflow-y: auto;
  z-index: 1000;
}

.search-result-item {
  padding: 5px 10px;
  cursor: pointer;
}

.search-result-item:hover {
  background-color: #f0f0f0;
}

/* Add this to the existing styles */
.ai-enhance-container {
  margin-bottom: 20px;
}

/* Add this new style for the time input */
.form-group input[type="time"] {
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ddd;
  border-radius: 5px;
}

.trainer-search-container {
  position: relative;
}

.trainer-search-container .search-results {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background-color: white;
  border: 1px solid #ccc;
  border-top: none;
  max-height: 200px;
  overflow-y: auto;
  z-index: 1000;
}

.trainer-search-container .search-result-item {
  padding: 5px 10px;
  cursor: pointer;
}

.trainer-search-container .search-result-item:hover {
  background-color: #f0f0f0;
}

/* Add these new styles for mobile devices */
@media (max-width: 767px) {
  .edit-sessions-page {
    padding: 10px;
  }

  .search-and-create {
    flex-direction: column;
  }

  .search-input,
  .session-select,
  .create-button {
    width: 100%;
    margin-bottom: 10px;
  }

  .sessions-list {
    grid-template-columns: 1fr;
  }

  .edit-modal {
    width: 100%;
    height: 100%;
    max-width: 100%;
    max-height: 100%;
    margin: 0;
    padding: 0;
    border-radius: 0;
  }

  :deep(.modal-container) {
    width: 100% !important;
    height: 100% !important;
    max-width: none !important;
    max-height: none !important;
    margin: 0;
    padding: 0;
    border-radius: 0;
  }

  :deep(.modal-content) {
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  :deep(.modal-body) {
    flex-grow: 1;
    overflow-y: auto;
  }

  .edit-form {
    padding: 0px;
  }

  .session-details {
    grid-template-columns: 1fr;
  }

  .form-group {
    margin-bottom: 15px;
  }

  .form-group input,
  .form-group select {
    width: 100%;
    padding: 12px;
    font-size: 16px; /* Minimum font size for iOS to prevent zooming */
  }

  .segments-container,
  .records-container {
    padding: 10px;
  }

  .segment,
  .record {
    min-width: unset;
    width: 100%;
  }

  .segment-header,
  .record-header {
    flex-wrap: wrap;
  }

  .record-details {
    flex-direction: column;
    align-items: stretch;
  }

  .exercise-search,
  .place-search {
    width: 100%;
    margin-bottom: 10px;
  }

  .repetitions-input,
  .description-input {
    width: 100%;
    margin-bottom: 10px;
  }

  .map-container {
    height: 300px;
  }

  .save-button,
  .cancel-button {
    width: 100%;
    margin: 10px 0;
    padding: 15px;
    font-size: 18px;
  }

  /* Improve touch targets */
  .remove-button,
  .add-button {
    padding: 10px;
    font-size: 16px;
  }

  .segment-drag-handle,
  .record-drag-handle {
    padding: 10px;
    font-size: 20px;
  }

  /* Adjust CustomSelect component for mobile */
  :deep(.custom-select) {
    width: 100%;
  }

  :deep(.custom-select .selected-option) {
    padding: 12px;
    font-size: 16px;
  }

  :deep(.custom-select .options) {
    max-height: 50vh;
  }

  :deep(.custom-select .option) {
    padding: 12px;
    font-size: 16px;
  }

  /* Adjust AIEnhanceSession component for mobile */
  .ai-enhance-container {
    margin-bottom: 15px;
  }

  /* Adjust search results for mobile */
  .search-results {
    max-height: 50vh;
  }

  .search-result-item {
    padding: 12px;
    font-size: 16px;
  }
}

/* Add this new style for the modal overlay */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9999998; /* Just below the modal */
}

/* Add this new style for the session type select in the toolbar */
.session-type-select {
  margin-right: 10px;
}

/* Remove these styles as they're no longer needed */
/*
.session-type-select {
  margin-right: 10px;
}

:deep(.v-toolbar__items .custom-select .selected-option i) {
  color: white;
  font-size: 24px;
}
*/

/* Update this style for the session type select in the form */
.session-type-select {
  width: 100%;
}

.trainer-type-group {
  margin-bottom: 15px;
}

.trainer-type-container {
  display: flex;
  align-items: flex-start;
  gap: 10px;
}

.session-type-select {
  width: 40px;
  flex-shrink: 0;
  align-self: center;
}

.trainer-input-container {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.trainer-input-container label {
  margin-bottom: 5px;
}

.trainer-search-container {
  position: relative;
  width: 100%;
}

.trainer-search-container input {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ddd;
  border-radius: 5px;
}

.workout-specifics {
  display: flex;
  gap: 10px;
  margin-top: 10px;
}

.workout-input {
  width: 100px;
  padding: 5px;
  font-size: 14px;
  border: 1px solid #ddd;
  border-radius: 3px;
}

@media (max-width: 767px) {
  .workout-specifics {
    flex-direction: column;
  }

  .workout-input {
    width: 100%;
  }
}
</style>