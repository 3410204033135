import { computed } from 'vue';
import { useMainStore } from '@/stores';

export function useUserState() {
  const store = useMainStore();
  
  const currentUser = computed(() => store.user);
  const isLoggedIn = computed(() => !!store.user);
  const isStaff = computed(() => store.user?.isStaff || false);

  return {
    currentUser,
    isLoggedIn,
    isStaff
  }
}