import { defineStore } from 'pinia'
import { createStore } from 'vuex'
import { api } from '@/services/api'
import { getImageUrl } from '@/utils/imageUtils'

// Pinia store
export const useMainStore = defineStore('main', {
  state: () => ({
    user: null,
    isAuthenticated: false,
    isStaff: false,
    exercises: [],
    equipment: [],
    places: [],
    passes: [],
    sessions: [],
    weatherData: {},
    userProfilePictures: {},
    isLoading: {
      exercises: false,
      equipment: false,
      places: false,
      passes: false,
      sessions: false,
      weather: false,
    },
    users: [],
    appUpdateTrigger: 0,
  }),
  getters: {
    isLoggedIn: (state) => !!state.user,
  },
  actions: {
    setUser(userData) {
      console.log('Setting user data in store:', userData);
      if (userData && typeof userData === 'object') {
        this.user = { ...userData };
        this.isAuthenticated = true;
        // Use Object.assign to update the state
        Object.assign(this.$state, {
          isStaff: userData.role === 'staff' || userData.isStaff || false
        });
        localStorage.setItem('userData', JSON.stringify(this.user));
      } else {
        this.user = null;
        this.isAuthenticated = false;
        // Use Object.assign to update the state
        Object.assign(this.$state, { isStaff: false });
        localStorage.removeItem('userData');
      }
      console.log('User data after setting:', this.user);
      console.log('Store setUser:', { userData, isAuthenticated: this.isAuthenticated, isStaff: this.isStaff });
      this.triggerAppUpdate();
    },
    async updateUser(userData) {
      console.log('Updating user data in store:', userData);
      if (userData && typeof userData === 'object') {
        this.user = { ...this.user, ...userData };
        localStorage.setItem('userData', JSON.stringify(this.user));
      } else {
        this.user = null;
        localStorage.removeItem('userData');
      }
      console.log('Updated user in store:', this.user);
      this.triggerAppUpdate();
    },
    async fetchCurrentUser(email) {
      if (!email) {
        console.error('No email provided for fetching current user');
        // You might want to throw an error or return null here
        return null;
      }
      try {
        const response = await api.getUserByEmail(email);
        if (response && response.data) {
          this.setUser(response.data);
          return response.data;
        }
        // Handle case where user data is not found
        console.error('User data not found for email:', email);
        return null;
      } catch (error) {
        console.error('Error fetching current user:', error);
        throw error;
      }
    },
    initializeUserFromLocalStorage() {
      const storedUserData = localStorage.getItem('userData')
      if (storedUserData) {
        try {
          const userData = JSON.parse(storedUserData)
          this.setUser(userData)
          console.log('User data initialized from localStorage:', userData)
        } catch (error) {
          console.error('Error parsing stored user data:', error)
          this.setUser(null)
        }
      }
    },
    async fetchExercises() {
      if (this.exercises.length === 0) {
        try {
          console.log('Store: Fetching exercises...')
          const response = await api.getExercises()
          console.log('Store: API response:', response)
          this.exercises = Array.isArray(response.data) ? response.data : []
          console.log('Store: Updated exercises:', this.exercises)
        } catch (error) {
          console.error('Store: Error fetching exercises:', error)
        }
      }
    },
    async fetchEquipment() {
      if (this.equipment.length === 0 && !this.isLoading.equipment) {
        this.isLoading.equipment = true
        try {
          const response = await api.getEquipment()
          this.equipment = Array.isArray(response) ? response : (response.data || [])
        } catch (error) {
          console.error('Error fetching equipment:', error)
        } finally {
          this.isLoading.equipment = false
        }
      }
    },
    async fetchPlaces() {
      if (this.places.length === 0 && !this.isLoading.places) {
        this.isLoading.places = true
        try {
          const response = await api.getPlaces()
          this.places = Array.isArray(response) ? response : (response.data || [])
        } catch (error) {
          console.error('Error fetching places:', error)
        } finally {
          this.isLoading.places = false
        }
      }
    },
    async fetchPasses() {
      if (this.passes.length === 0 && !this.isLoading.passes) {
        this.isLoading.passes = true
        try {
          const response = await api.getPasses()
          console.log('Fetched passes:', response)
          this.passes = Array.isArray(response) ? response : (response.data || [])
          console.log('Stored passes:', this.passes)
          // Process passes to include weather data
          await this.fetchWeatherForPasses()
          // Fetch user profile pictures
          await this.fetchUserProfilePictures()
        } catch (error) {
          console.error('Error fetching passes:', error)
        } finally {
          this.isLoading.passes = false
        }
      }
    },
    async fetchSessions() {
      if (this.sessions.length === 0 && !this.isLoading.sessions) {
        this.isLoading.sessions = true
        try {
          const response = await api.getSessions()
          this.sessions = Array.isArray(response) ? response : (response.data || [])
        } catch (error) {
          console.error('Error fetching sessions:', error)
        } finally {
          this.isLoading.sessions = false
        }
      }
    },
    async fetchWeatherForPasses() {
      this.isLoading.weather = true
      const today = new Date()
      today.setHours(0, 0, 0, 0)
      const tenDaysFromNow = new Date(today)
      tenDaysFromNow.setDate(today.getDate() + 10)

      for (const pass of this.passes) {
        const passDate = new Date(pass.date)
        passDate.setHours(0, 0, 0, 0)
        
        if (passDate >= today && passDate <= tenDaysFromNow) {
          const dateString = passDate.toISOString().split('T')[0]
          if (!this.weatherData[dateString]) {
            try {
              const weatherResponse = await api.getWeather({ 
                date: dateString, 
                lat: pass.latitude, 
                lon: pass.longitude 
              })
              this.weatherData[dateString] = weatherResponse.data
            } catch (error) {
              console.error('Error fetching weather for', dateString, ':', error)
            }
          }
        }
      }
      this.isLoading.weather = false
    },
    getWeatherForPass(pass) {
      console.log('Getting weather for pass:', pass)
      const passDate = new Date(pass.date)
      const dateString = passDate.toISOString().split('T')[0]
      const passTime = pass.time.split(':')[0]
      console.log('Date string:', dateString, 'Pass time:', passTime)

      if (this.weatherData[dateString]) {
        console.log('Weather data found for date:', this.weatherData[dateString])
        const forecast = this.weatherData[dateString].find(f => {
          const forecastHour = new Date(f.validTime).getHours()
          return forecastHour === parseInt(passTime)
        })

        if (forecast) {
          console.log('Forecast found:', forecast)
          return {
            icon: forecast.parameters.find(p => p.name === 'Wsymb2').values[0],
            temperature: Math.round(forecast.parameters.find(p => p.name === 't').values[0])
          }
        } else {
          console.log('No forecast found for the specific time')
        }
      } else {
        console.log('No weather data found for the date')
      }
      console.log('Weather data for pass:', this.weatherData)
      return this.weatherData
    },
    async createBooking(passId, userId) {
      try {
        await api.createBooking(passId, userId)
        // Update local state
        const passIndex = this.passes.findIndex(pass => pass.id === passId)
        if (passIndex !== -1) {
          this.passes[passIndex].users.push(userId)
        }
      } catch (error) {
        console.error('Error creating booking:', error)
        throw error
      }
    },
    async deleteBooking(passId, userId) {
      try {
        await api.deleteBooking(passId, userId)
        // Update local state
        const passIndex = this.passes.findIndex(pass => pass.id === passId)
        if (passIndex !== -1) {
          this.passes[passIndex].users = this.passes[passIndex].users.filter(id => id !== userId)
        }
      } catch (error) {
        console.error('Error deleting booking:', error)
        throw error
      }
    },
    async fetchUserProfilePictures() {
      const userIds = new Set()
      this.passes.forEach(pass => {
        if (Array.isArray(pass.users)) {
          pass.users.forEach(userId => userIds.add(userId))
        }
      })

      for (const userId of userIds) {
        if (!this.userProfilePictures[userId]) {
          try {
            const response = await api.getUserById(userId)
            const user = response.data
            if (user && user.profilePicture) {
              this.userProfilePictures[userId] = getImageUrl(user.profilePicture)
            } else {
              this.userProfilePictures[userId] = require('@/assets/default-profile-picture.webp')
            }
          } catch (error) {
            console.error(`Error fetching profile picture for user ${userId}:`, error)
            this.userProfilePictures[userId] = require('@/assets/default-profile-picture.webp')
          }
        }
      }
    },
    getUserProfilePicture(userId) {
      return this.userProfilePictures[userId] || require('@/assets/default-profile-picture.webp')
    },
    async fetchWeatherData() {
      if (!this.isLoading.weather) {
        this.isLoading.weather = true
        try {
          const response = await api.getWeather() // Ändra detta om din API-metod heter något annat
          this.weatherData = response.data
          console.log('Fetched weather data:', this.weatherData)
        } catch (error) {
          console.error('Error fetching weather data:', error)
        } finally {
          this.isLoading.weather = false
        }
      }
    },
    async getUserById(userId) {
      try {
        const response = await api.getUserById(userId)
        return response.data
      } catch (error) {
        console.error(`Error fetching user data for user ${userId}:`, error)
        throw error
      }
    },
    async addExercise(exerciseData) {
      try {
        console.log('Sending exercise data to API:', exerciseData);
        const response = await api.createExercise(exerciseData);
        console.log('API response for createExercise:', response);

        if (response.error) {
          throw new Error(response.error);
        }

        let newExercise = response;

        console.log('Parsed new exercise:', newExercise);

        if (!newExercise || typeof newExercise !== 'object') {
          throw new Error('Invalid exercise data received from API');
        }

        if (!newExercise.id) {
          throw new Error('No ID returned from the API for the new exercise');
        }

        this.exercises.push(newExercise);
        return newExercise;
      } catch (error) {
        console.error('Error adding exercise to store:', error);
        throw new Error(`Failed to add exercise: ${error.message}`);
      }
    },
    setUsers(users) {
      this.users = users
    },
    async fetchUsers() {
      if (this.users.length === 0) {
        try {
          console.log('Store: Fetching users...')
          const response = await api.getUsers()
          console.log('Store: API response for users:', response)
          this.users = Array.isArray(response.data) ? response.data : []
          console.log('Store: Updated users:', this.users)
        } catch (error) {
          console.error('Store: Error fetching users:', error)
        }
      }
    },
    triggerAppUpdate() {
      this.appUpdateTrigger = Date.now();
    },
    updateUserFCMToken(token) {
      this.user.fcmToken = token;
    }
  },
})

// Vuex store (you may want to consider removing this if you're fully migrating to Pinia)
export const store = createStore({
  state: {
    user: null,
    exercises: [],
  },
  mutations: {
    SET_USER(state, user) {
      state.user = user;
    },
    SET_EXERCISES(state, exercises) {
      console.log('Mutation: SET_EXERCISES', exercises);
      state.exercises = exercises;
    },
  },
  actions: {
    updateUser({ commit }, userData) {
      commit('SET_USER', userData);
    },
    async fetchExercises({ commit }) {
      console.log('Action: fetchExercises started');
      try {
        const response = await api.getExercises();
        console.log('API response:', response);
        commit('SET_EXERCISES', response.data);
      } catch (error) {
        console.error('Error fetching exercises:', error);
      }
    },
  },
  getters: {
    isLoggedIn: state => !!state.user,
    isStaff: state => state.user?.isStaff || false,
  },
});

export default store;
