const originalConsoleError = console.error;
console.error = (...args) => {
  if (args[0] instanceof Error && args[0].message.includes('ResizeObserver loop')) {
    return;
  }
  originalConsoleError.apply(console, args);
};

import { createApp } from 'vue';
import { VueFire, VueFireAuth } from 'vuefire';
import { createPinia } from 'pinia';
import { firebaseApp } from './firebase';
import { getAuth, onAuthStateChanged } from 'firebase/auth'; // Import these
import App from './App.vue';
import router from './router';
import vuetify from './plugins/vuetify';
import { store, useMainStore } from './stores';
import Toast from "vue-toast-notification";
import 'vue-toast-notification/dist/theme-default.css';
import '@mdi/font/css/materialdesignicons.css';

const app = createApp(App);
const pinia = createPinia();

app.use(pinia);
const mainStore = useMainStore();

app.use(VueFire, {
  firebaseApp,
  modules: [
    VueFireAuth(),
  ],
});

app.use(router);
app.use(vuetify);
app.use(store);
app.use(Toast);

// Initialize user from localStorage
mainStore.initializeUserFromLocalStorage();

// Fetch exercises when the app is created
mainStore.fetchExercises();

// Handle authentication state changes
const auth = getAuth(firebaseApp);
const unsubscribe = onAuthStateChanged(auth, async (firebaseUser) => {
  if (firebaseUser) {
    console.log('Firebase user authenticated:', firebaseUser);
    try {
      let userEmail = firebaseUser.email;
      if (!userEmail && firebaseUser.providerData && firebaseUser.providerData.length > 0) {
        userEmail = firebaseUser.providerData[0].email;
      }

      if (!userEmail) {
        console.error('User email is missing from authentication result');
        // Handle the case where email is missing (e.g., show an error message)
        return;
      }

      const userData = await mainStore.fetchCurrentUser(userEmail);
      if (userData && userData.id) {
        console.log('User data fetched and updated in store:', userData);
      } else {
        console.error('Failed to fetch or update user data');
        // Handle the case where user data couldn't be fetched (e.g., show an error message)
      }
    } catch (error) {
      console.error('Error fetching user data on auth state change:', error);
      // Handle the error (e.g., show an error message)
    }
  } else {
    console.log('No Firebase user');
    mainStore.setUser(null);
  }
});

// Save the original unmount method
const originalUnmount = app.unmount;

// Override the unmount method
app.unmount = () => {
  unsubscribe();
  originalUnmount.call(app);
};

app.mount('#app');

// Add global error handler
app.config.errorHandler = (err, instance, info) => {
  console.error('Global error:', err);
  console.log('Error instance:', instance);
  console.log('Error info:', info);
};
