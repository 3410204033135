<template>
  <div class="edit-page">
    <h1>Hantera utrustning</h1>
    
    <!-- Add new equipment button -->
    <button
      class="btn btn-primary"
      @click="startAddingNewEquipment"
    >
      Lägg till ny utrustning
    </button>

    <!-- Dropdown list of all equipment -->
    <div
      v-if="!isAddingNewEquipment && !selectedEquipment"
      class="equipment-dropdown"
    >
      <select
        v-model="selectedEquipmentId"
        @change="selectEquipmentFromDropdown"
      >
        <option value="">
          Välj utrustning att redigera
        </option>
        <option
          v-for="item in allEquipment"
          :key="item.id"
          :value="item.id"
        >
          {{ item.name }}
        </option>
      </select>
    </div>

    <!-- Search bar (only show when not adding/editing equipment) -->
    <div
      v-if="!isAddingNewEquipment && !selectedEquipment"
      class="search-bar"
    >
      <input
        v-model="searchTerm"
        placeholder="Sök efter utrustning..."
        @input="searchEquipment"
      >
    </div>

    <!-- Search results (only show when not adding/editing equipment) -->
    <div
      v-if="!isAddingNewEquipment && !selectedEquipment && searchResults.length > 0"
      class="search-results"
    >
      <div
        v-for="item in searchResults"
        :key="item.id"
        class="search-result-item"
        @click="selectEquipment(item)"
      >
        {{ item.name }}
      </div>
    </div>

    <!-- Edit form -->
    <div
      v-if="selectedEquipment"
      class="edit-form"
    >
      <h2>{{ isAddingNewEquipment ? 'Lägg till ny utrustning' : 'Redigerar: ' + selectedEquipment.name }}</h2>
      
      <!-- Form fields -->
      <form @submit.prevent="saveEquipment">
        <input
          v-model="selectedEquipment.name"
          placeholder="Namn"
          required
        >
        <textarea
          v-model="selectedEquipment.description"
          placeholder="Beskrivning"
        />
        
        <!-- Image uploader and preview -->
        <div>
          <h3>Bilder</h3>
          <ImageUploader
            :accepted-file-types="'image/*'"
            :button-text="'Ladda upp bild'"
            :button-color="'primary'"
            :max-file-size="5 * 1024 * 1024"
            @upload-success="handleImageUpload"
            @upload-error="handleUploadError"
          />
        </div>
        
        <!-- Display uploaded images -->
        <div
          v-if="selectedEquipment.imageUrl && selectedEquipment.imageUrl.length > 0"
          class="image-preview-container"
        >
          <div
            v-for="(image, index) in validImages"
            :key="index"
            class="image-preview"
          >
            <img
              :src="getFullImageUrl(image)"
              alt="Equipment Image"
            >
            <v-btn
              icon
              small
              color="error"
              @click="removeImage(index)"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </div>
        </div>

        <!-- Places list -->
        <div class="places-list">
          <h3>Kopplade platser</h3>
          <div
            v-for="place in allPlaces"
            :key="place.id"
            class="place-item"
          >
            <v-checkbox
              v-model="selectedEquipment.platsId"
              :label="place.name"
              :value="place.id"
              hide-details
            />
          </div>
        </div>

        <div class="button-group">
          <button
            type="submit"
            class="btn btn-primary"
          >
            {{ isAddingNewEquipment ? 'Lägg till' : 'Spara' }}
          </button>
          <button
            type="button"
            class="btn btn-secondary"
            @click="cancelEditing"
          >
            Avbryt
          </button>
          <button
            v-if="!isAddingNewEquipment"
            type="button"
            class="btn btn-secondary"
            @click="copyEquipment"
          >
            Kopiera
          </button>
          <button
            v-if="!isAddingNewEquipment"
            type="button"
            class="btn btn-danger"
            @click="deleteEquipment"
          >
            Ta bort
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, computed } from 'vue'
import api from '@/services/api'
import { getImageUrl } from '@/utils/imageUtils'
import ImageUploader from '@/components/ImageUploader.vue'

export default {
  name: 'EditEquipmentPage',
  components: {
    ImageUploader
  },
  setup() {
    const allEquipment = ref([])
    const selectedEquipment = ref(null)
    const isAddingNewEquipment = ref(false)
    const searchTerm = ref('')
    const searchResults = ref([])
    const selectedEquipmentId = ref('')
    const allPlaces = ref([])

    const fetchEquipment = async () => {
      try {
        const response = await api.getEquipment()
        allEquipment.value = response.data
      } catch (error) {
        console.error('Error fetching equipment:', error)
      }
    }

    const fetchPlaces = async () => {
      try {
        const response = await api.getPlaces()
        allPlaces.value = response.data
      } catch (error) {
        console.error('Error fetching places:', error)
      }
    }

    onMounted(() => {
      fetchEquipment()
      fetchPlaces()
    })

    const startAddingNewEquipment = () => {
      selectedEquipment.value = { name: '', description: '', imageUrl: '', platsId: [] }
      isAddingNewEquipment.value = true
    }

    const selectEquipmentFromDropdown = () => {
      if (selectedEquipmentId.value) {
        const selected = allEquipment.value.find(item => item.id === selectedEquipmentId.value)
        if (selected) {
          selectEquipment(selected)
        }
      }
    }

    const selectEquipment = (equipment) => {
      selectedEquipment.value = { ...equipment }
      isAddingNewEquipment.value = false
    }

    const validImages = computed(() => {
      return selectedEquipment.value?.imageUrl?.filter(url => url !== '') || []
    })

    const handleImageUpload = (imagePath) => {
      console.log('Image uploaded:', imagePath);
      if (!selectedEquipment.value.imageUrl) {
        selectedEquipment.value.imageUrl = [];
      }
      selectedEquipment.value.imageUrl.push(imagePath);
      console.log('Updated equipment image URLs:', selectedEquipment.value.imageUrl);
    };

    const handleUploadError = (errorMessage) => {
      console.error('Upload error:', errorMessage);
      // You can add code here to display the error message to the user
    }

    const removeImage = (index) => {
      selectedEquipment.value.imageUrl.splice(index, 1);
    };

    const saveEquipment = async () => {
      try {
        const equipmentData = {
          name: selectedEquipment.value.name,
          description: selectedEquipment.value.description,
          platsId: selectedEquipment.value.platsId || [],
          imageUrl: selectedEquipment.value.imageUrl || [],
          videoUrl: selectedEquipment.value.videoUrl || []
        };

        console.log('Saving equipment data:', equipmentData);

        if (isAddingNewEquipment.value) {
          await api.createEquipment(equipmentData);
        } else {
          await api.updateEquipment(selectedEquipment.value.id, equipmentData);
        }

        console.log('Equipment saved successfully');
        await fetchEquipment();
        selectedEquipment.value = null;
        isAddingNewEquipment.value = false;
      } catch (error) {
        console.error('Error saving equipment:', error);
      }
    }

    const copyEquipment = () => {
      const equipmentCopy = { ...selectedEquipment.value }
      delete equipmentCopy.id
      equipmentCopy.name += ' (kopia)'
      selectedEquipment.value = equipmentCopy
      isAddingNewEquipment.value = true
    }

    const deleteEquipment = async () => {
      if (confirm('Är du säker på att du vill ta bort denna utrustning?')) {
        try {
          await api.deleteEquipment(selectedEquipment.value.id)
          fetchEquipment()
          selectedEquipment.value = null
        } catch (error) {
          console.error('Error deleting equipment:', error)
          // Handle error (e.g., show error message to user)
        }
      }
    }

    const cancelEditing = () => {
      selectedEquipment.value = null
      isAddingNewEquipment.value = false
    }

    const searchEquipment = () => {
      if (searchTerm.value) {
        searchResults.value = allEquipment.value.filter(item =>
          item.name.toLowerCase().includes(searchTerm.value.toLowerCase())
        )
      } else {
        searchResults.value = []
      }
    }

    return {
      allEquipment,
      selectedEquipment,
      isAddingNewEquipment,
      searchTerm,
      searchResults,
      selectedEquipmentId,
      allPlaces,
      startAddingNewEquipment,
      selectEquipmentFromDropdown,
      selectEquipment,
      saveEquipment,
      copyEquipment,
      deleteEquipment,
      cancelEditing,
      searchEquipment,
      handleImageUpload,
      handleUploadError,
      removeImage,
      getFullImageUrl: getImageUrl,
      validImages,
    }
  }
}
</script>

<style scoped>
.edit-page {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

h1, h2 {
  color: #333;
  margin-bottom: 20px;
}

.search-bar input, .equipment-dropdown select {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ced4da;
  border-radius: 4px;
  margin-bottom: 20px;
}

.search-results {
  margin-top: 20px;
  background-color: #f8f9fa;
  border-radius: 8px;
  overflow: hidden;
}

.search-result-item {
  padding: 10px;
  cursor: pointer;
  border-bottom: 1px solid #e9ecef;
}

.search-result-item:hover {
  background-color: #e9ecef;
}

.edit-form {
  margin-top: 20px;
  background-color: #f8f9fa;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

form label {
  display: block;
  margin-bottom: 10px;
}

form input, form textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #ced4da;
  border-radius: 4px;
}

.button-group {
  margin-top: 20px;
}

.btn {
  display: inline-block;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-weight: bold;
  transition: background-color 0.3s;
  margin-right: 10px;
  margin-bottom: 10px;
}

.btn-primary {
  background-color: #007bff;
  color: white;
}

.btn-primary:hover {
  background-color: #0056b3;
}

.btn-secondary {
  background-color: #6c757d;
  color: white;
}

.btn-secondary:hover {
  background-color: #545b62;
}

.btn-danger {
  background-color: #dc3545;
  color: white;
}

.btn-danger:hover {
  background-color: #c82333;
}

fieldset {
  border: 1px solid #ced4da;
  border-radius: 4px;
  padding: 10px;
  margin-bottom: 20px;
}

legend {
  padding: 0 10px;
}

.checkbox-item {
  margin-bottom: 10px;
}

.checkbox-item input[type="checkbox"] {
  margin-right: 10px;
}

.checkbox-item label {
  display: inline-block;
}

.image-preview-container {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 10px;
}

.image-preview {
  position: relative;
  width: 200px;
  height: 200px;
}

.image-preview img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 4px;
}

.image-preview .v-btn {
  position: absolute;
  top: 5px;
  right: 5px;
}

.places-list {
  margin-top: 20px;
}

.place-item {
  margin-bottom: 10px;
}
</style>