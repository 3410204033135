<template>
  <nav class="main-nav">
    <div class="menu-toggle" @click="toggleMenu">
      <i class="fad fa-bars"></i>
    </div>
    <ul class="menu" :class="{ 'menu-open': isMenuOpen }">
      <MenuItem
        v-for="item in topLevelItems"
        :key="item.id"
        :item="item"
        :is-mobile="isMobile"
        :is-main-level="true"
        @closeMenu="closeMenu"
        @auth-action="handleAuthAction"
      />
    </ul>
  </nav>
</template>

<script>
import { ref, computed, onMounted, onUnmounted, provide, watch } from 'vue'
import { useRouter } from 'vue-router'
import { getAuth, signOut } from 'firebase/auth'
import menuStructure from '@/assets/menu-structure.json'
import MenuItem from './MenuItem.vue'

export default {
  name: 'MainMenu',
  components: {
    MenuItem,
  },
  props: {
    isAuthenticated: {
      type: Boolean,
      required: true
    },
    isStaff: {
      type: Boolean,
      required: true
    }
  },
  setup(props) {
    const isMenuOpen = ref(false)
    const isMobile = ref(false)
    const router = useRouter()
    const openSubmenuIds = ref(new Set())
    const mainLevelIds = ref(new Set())

    const updateIsMobile = () => {
      isMobile.value = window.innerWidth <= 768
    }

    onMounted(() => {
      updateIsMobile()
      window.addEventListener('resize', updateIsMobile)
    })

    onUnmounted(() => {
      window.removeEventListener('resize', updateIsMobile)
    })

    const filteredMenuItems = computed(() => {
      console.log('MainMenu - Filtering menu items:', { isAuthenticated: props.isAuthenticated, isStaff: props.isStaff })
      let items = menuStructure.map(item => {
        if (!item.enabled) {
          console.log(`MainMenu - Item ${item.id} is disabled`)
          return null
        }
        
        if (item.id === 'authentication') {
          console.log('MainMenu - Processing authentication item')
          if (item.children && Array.isArray(item.children)) {
            const authChildren = item.children.filter(child => {
              if (child.id === 'login' || child.id === 'logout') {
                const shouldShow = child.id === (props.isAuthenticated ? 'logout' : 'login')
                console.log(`MainMenu - ${child.id} visibility:`, shouldShow)
                return shouldShow
              }
              return false
            })
            if (authChildren.length > 0) {
              return { ...authChildren[0], id: 'authentication' }
            }
          }
          console.log('MainMenu - Authentication item has no valid children')
          return null
        }
        
        if (props.isStaff && item.staff) {
          console.log(`MainMenu - Showing staff item: ${item.id}`)
          return item
        }
        
        if (props.isAuthenticated && item.member) {
          console.log(`MainMenu - Showing member item: ${item.id}`)
          return item
        }
        
        if (item.public) {
          console.log(`MainMenu - Showing public item: ${item.id}`)
          return item
        }
        
        console.log(`MainMenu - Hiding item: ${item.id}`)
        return null
      }).filter(Boolean) // Remove null items

      // Manually add login/logout option if not present
      const hasAuthItem = items.some(item => item.id === 'authentication')
      if (!hasAuthItem) {
        console.log('MainMenu - Manually adding authentication item')
        items.push({
          id: 'authentication',
          text: props.isAuthenticated ? 'Logga Ut' : 'Logga In',
          icon: props.isAuthenticated ? 'fad fa-sign-out-alt' : 'fad fa-sign-in-alt',
          'router-link': props.isAuthenticated ? '/logout' : '/login',
          order: 99 // Adjust this value to position the item in the menu
        })
      }

      return items
    })

    const buildMenuTree = (items, parentId = null) => {
      return items
        .filter(item => item.parent === parentId || (!item.parent && parentId === null))
        .map(item => ({
          ...item,
          children: buildMenuTree(items, item.id)
        }))
        .sort((a, b) => (a.order || 0) - (b.order || 0));
    };

    const topLevelItems = computed(() => {
      console.log('MainMenu - Building top level items')
      const items = buildMenuTree(filteredMenuItems.value);
      console.log('MainMenu - Top level items:', items.map(item => ({ id: item.id, text: item.text, children: item.children ? item.children.length : 0 })))
      return items
    })

    const toggleMenu = () => {
      isMenuOpen.value = !isMenuOpen.value
    }

    const closeMenu = () => {
      isMenuOpen.value = false
      openSubmenuIds.value.clear()
    }

    const handleAuthAction = async (item) => {
      console.log('MainMenu - Handling auth action:', item.id)
      if (item.id === 'authentication' && props.isAuthenticated) {
        try {
          const auth = getAuth()
          await signOut(auth)
          console.log('MainMenu - User signed out successfully')
          router.push('/login')
        } catch (error) {
          console.error('MainMenu - Error signing out:', error)
        }
      } else {
        router.push(item['router-link'] || '/login')
      }
      closeMenu()
    }

    const toggleSubmenu = (itemId, isMainLevel) => {
      if (isMainLevel) {
        // Close all other main-level dropdowns
        openSubmenuIds.value.forEach(id => {
          if (mainLevelIds.value.has(id) && id !== itemId) {
            openSubmenuIds.value.delete(id)
          }
        })
      }

      if (openSubmenuIds.value.has(itemId)) {
        openSubmenuIds.value.delete(itemId)
      } else {
        openSubmenuIds.value.add(itemId)
      }

      if (isMainLevel) {
        mainLevelIds.value.add(itemId)
      }
    }

    const isSubmenuOpen = (itemId) => openSubmenuIds.value.has(itemId)

    provide('openSubmenuIds', openSubmenuIds)
    provide('toggleSubmenu', toggleSubmenu)
    provide('isSubmenuOpen', isSubmenuOpen)
    provide('handleAuthAction', handleAuthAction)

    watch(() => props.isAuthenticated, (newValue) => {
      console.log('MainMenu - isAuthenticated changed:', newValue)
    }, { immediate: true })

    watch(() => props.isStaff, (newValue) => {
      console.log('MainMenu - isStaff changed:', newValue)
    }, { immediate: true })

    return {
      isMenuOpen,
      topLevelItems,
      toggleMenu,
      closeMenu,
      isMobile,
      openSubmenuIds,
      mainLevelIds,
      handleAuthAction,
    }
  }
}
</script>

<style scoped>
.main-nav {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: #333;
  z-index: 1000;
  font-size: 16px;
}

.menu {
  display: flex;
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.menu-item {
  position: relative;
  list-style: none;
}

.menu-item-content {
  display: flex;
  align-items: center;
  color: white;
  padding: 15px 20px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-size: 1em;
}

.menu-item-content:hover {
  background-color: #444;
}

.menu-toggle {
  display: none;
  color: white;
  font-size: 24px;
  padding: 10px;
  cursor: pointer;
}

@media (max-width: 768px) {
  .menu-toggle {
    display: block;
  }

  .menu {
    display: none;
    flex-direction: column;
    position: fixed;
    top: 50px;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #333;
    overflow-y: auto;
  }

  .menu-open {
    display: flex;
  }
}

/* New styles to fix the top-level menu */
@media (min-width: 769px) {
  .menu {
    height: 50px; /* Adjust this value to match your top-level menu height */
    overflow: visible;
  }

  .menu > .menu-item {
    height: 100%;
  }

  .menu > .menu-item > .menu-item-content {
    height: 100%;
    display: flex;
    align-items: center;
  }
}
</style>
