import axios from 'axios';

const API_BASE_URL = 'https://api.bananoutdoor.com/api';
const API_KEY = process.env.VUE_APP_API_KEY;

const axiosInstance = axios.create({
  baseURL: process.env.VUE_APP_API_BASE_URL || API_BASE_URL,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'Ocp-Apim-Subscription-Key': API_KEY
  },
  params: {
    'subscription-key': API_KEY
  }
});

// Interceptor to remove the subscription-key from query params if it's in the header
axiosInstance.interceptors.request.use(config => {
  if (config.headers['Ocp-Apim-Subscription-Key']) {
    delete config.params['subscription-key'];
  }
  return config;
});

// Define getUserByEmail function
const getUserByEmail = (email) => axiosInstance.get(`/users/email/${encodeURIComponent(email)}`);

export const api = {
  // Users
  getUsers: () => axiosInstance.get('/users'),
  getUserById: (id) => axiosInstance.get(`/users/${id}`),
  createUser: (userData) => axiosInstance.post('/users', userData),
  updateUser: (id, userData) => axiosInstance.patch(`/users/${id}`, userData),
  deleteUser: (id) => axiosInstance.delete(`/users/${id}`),
  countUsers: () => axiosInstance.get('/users/count'),
  searchUsers: (params) => axiosInstance.get('/users/search', { params }),
  getUserByEmail, // Ensure getUserByEmail is exported

  // User related operations
  updateUserStatistics: (userId, data) => axiosInstance.put(`/users/${userId}/statistics`, data),
  addUserPayment: (userId, data) => axiosInstance.post(`/users/${userId}/payments`, data),
  updateUserPayment: (userId, paymentId, data) => axiosInstance.put(`/users/${userId}/payments/${paymentId}`, data),
  deleteUserPayment: (userId, paymentId) => axiosInstance.delete(`/users/${userId}/payments/${paymentId}`),
  addUserAchievement: (userId, data) => axiosInstance.post(`/users/${userId}/achievements`, data),
  updateUserAchievement: (userId, achievementId, data) => axiosInstance.put(`/users/${userId}/achievements/${achievementId}`, data),
  deleteUserAchievement: (userId, achievementId) => axiosInstance.delete(`/users/${userId}/achievements/${achievementId}`),
  updateUserNotifications: (userId, data) => axiosInstance.put(`/users/${userId}/notifications`, data),
  getUserFavorites: (userId) => axiosInstance.get(`/users/${userId}/favorites`),
  addUserFavorite: (userId, favoriteData) => axiosInstance.post(`/users/${userId}/favorites`, favoriteData),
  removeUserFavorite: (userId, favoriteId) => axiosInstance.delete(`/users/${userId}/favorites/${favoriteId}`),
  getUserFeedback: (userId) => axiosInstance.get(`/users/${userId}/feedback`),
  addUserFeedback: (userId, data) => axiosInstance.post(`/users/${userId}/feedback`, data),
  updateUserFeedback: (userId, feedbackId, data) => axiosInstance.put(`/users/${userId}/feedback/${feedbackId}`, data),
  deleteUserFeedback: (userId, feedbackId) => axiosInstance.delete(`/users/${userId}/feedback/${feedbackId}`),
  getUserRatings: (userId) => axiosInstance.get(`/users/${userId}/ratings`),
  addUserRating: (userId, data) => axiosInstance.post(`/users/${userId}/ratings`, data),
  updateUserRating: (userId, ratingId, data) => axiosInstance.put(`/users/${userId}/ratings/${ratingId}`, data),
  getUserInvites: (userId) => axiosInstance.get(`/invitations/search?inviterId=${userId}`),
  addUserInvite: (userId, data) => axiosInstance.post(`/users/${userId}/invites`, data),
  updateUserInvite: (userId, inviteId, data) => axiosInstance.put(`/users/${userId}/invites/${inviteId}`, data),
  deleteUserInvite: (userId, inviteId) => axiosInstance.delete(`/users/${userId}/invites/${inviteId}`),
  getUserInvitesCount: (userId) => axiosInstance.get(`/invitations/score/${userId}`),

  // Passes
  getPasses: () => axiosInstance.get('/passes'),
  getPassById: (id) => axiosInstance.get(`/passes/${id}`),
  createPass: (passData) => axiosInstance.post('/passes', passData),
  updatePass: (id, passData) => axiosInstance.put(`/passes/${id}`, passData),
  deletePass: (id) => axiosInstance.delete(`/passes/${id}`),
  getPassesByUserId: (userId) => axiosInstance.get(`/passes/user/${userId}`),
  getValidUpcomingPasses: () => axiosInstance.get('/passes?valid=true&upcoming=true'),

  // Bookings
  createBooking: async (passId, userId) => {
    console.log('Creating booking for passId:', passId, 'with userId:', userId);
    const response = await axiosInstance.post(`/passes/${passId}/bookings`, `"${userId}"`, {
      headers: {
        'Content-Type': 'application/json'
      }
    });
    console.log('Create booking response:', response);
    return response;
  },

  deleteBooking: async (passId, bookingId) => {
    console.log('Deleting booking:', bookingId, 'for passId:', passId);
    const response = await axiosInstance.delete(`/passes/${passId}/bookings/${bookingId}`);
    console.log('Delete booking response:', response);
    return response;
  },

  getPassBookings: (passId) => axiosInstance.get(`/passes/${passId}/bookings`),

  // Places
  getPlaces: () => axiosInstance.get('/places'),
  getPlaceById: (id) => axiosInstance.get(`/places/${id}`),
  createPlace: (placeData) => axiosInstance.post('/places', placeData),
  updatePlace: (id, placeData) => axiosInstance.put(`/places/${id}`, placeData),
  deletePlace: (id) => axiosInstance.delete(`/places/${id}`),

  // Equipment
  getEquipment: () => axiosInstance.get('/equipment'),
  getEquipmentById: (id) => axiosInstance.get(`/equipment/${id}`),
  createEquipment: (equipmentData) => axiosInstance.post('/equipment', equipmentData),
  updateEquipment: (id, equipmentData) => axiosInstance.put(`/equipment/${id}`, equipmentData),
  deleteEquipment: (id) => axiosInstance.delete(`/equipment/${id}`),

  // Sessions
  getSessions: () => axiosInstance.get('/sessions'),
  getSessionById: (id) => axiosInstance.get(`/sessions/${id}`),
  createSession: (sessionData) => axiosInstance.post('/sessions', sessionData),
  updateSession: (id, sessionData) => axiosInstance.put(`/sessions/${id}`, sessionData),
  deleteSession: (id) => axiosInstance.delete(`/sessions/${id}`),
  getSearchSessions: (params) => axiosInstance.get('/sessions/search', { params }),

  // Exercises
  getExercises: () => axiosInstance.get('/exercises'),
  getExerciseById: (id) => axiosInstance.get(`/exercises/${id}`),
  createExercise: async (exerciseData) => {
    try {
      console.log('Sending exercise data to API:', exerciseData);
      const response = await axiosInstance.post('/exercises', exerciseData, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
      console.log('Raw API response:', response);
      if (response.data.error) {
        throw new Error(response.data.error);
      }
      return response.data;
    } catch (error) {
      console.error('Error creating exercise:', error.response?.data || error.message);
      throw new Error(`Failed to create exercise: ${error.response?.data?.message || error.message}`);
    }
  },
  updateExercise: (id, exerciseData) => axiosInstance.put(`/exercises/${id}`, exerciseData),
  deleteExercise: (id) => axiosInstance.delete(`/exercises/${id}`),

  // Weather
  getWeather: () => axiosInstance.get('/weather'),

  // Elevation
  getElevation: (locations) => axiosInstance.get(`/elevation?locations=${locations}`),

  // New method to fetch schedules without a user ID
  getSchedules: () => axiosInstance.get('/sessions'),

  // New methods for Messages
  getMessages: () => axiosInstance.get('/messages'),
  createMessage: (messageData) => axiosInstance.post('/messages', messageData),
  getMessageById: (messageId) => axiosInstance.get(`/messages/${messageId}`),
  updateMessage: (messageId, messageData) => axiosInstance.put(`/messages/${messageId}`, messageData),
  deleteMessage: (messageId) => axiosInstance.delete(`/messages/${messageId}`),
  searchMessages: (params) => axiosInstance.get('/messages/search', { params }),
  getUserMessages: () => axiosInstance.get(`/messages/`),

  // New methods for Subscriptions (webhooks)
  getSubscriptions: () => axiosInstance.get('/subscriptions'),
  createSubscription: (subscriptionData) => axiosInstance.post('/subscriptions', subscriptionData),
  getSubscriptionById: (subscriptionId) => axiosInstance.get(`/subscriptions/${subscriptionId}`),
  updateSubscription: (subscriptionId, subscriptionData) => axiosInstance.put(`/subscriptions/${subscriptionId}`, subscriptionData),
  deleteSubscription: (subscriptionId) => axiosInstance.delete(`/subscriptions/${subscriptionId}`),
  searchSubscriptions: (params) => axiosInstance.get('/subscriptions/search', { params }),
  getPendingSubscriptions: () => axiosInstance.get('/users/pending-subscriptions'),

  // New methods for Invitations
  getInvitations: () => axiosInstance.get('/invitations'),
  createInvitation: (invitationData) => axiosInstance.post('/invitations', invitationData),
  getInvitationById: (inviteId) => axiosInstance.get(`/invitations/${inviteId}`),
  updateInvitation: (inviteId, invitationData) => axiosInstance.put(`/invitations/${inviteId}`, invitationData),
  deleteInvitation: (inviteId) => axiosInstance.delete(`/invitations/${inviteId}`),
  searchInvitations: (params) => axiosInstance.get('/invitations/search', { params }),
  getRandomInviteCode: () => axiosInstance.get('/invitations/random-code'),
  updateInvitationStatus: (inviteId, statusData) => axiosInstance.put(`/invitations/${inviteId}/status`, statusData),
  getInvitationStats: () => axiosInstance.get('/invitations/stats'),
  getUserInvitationScore: (userId) => axiosInstance.get(`/invitations/score/${userId}`),

  // New methods for Passes
  searchPasses: (params) => axiosInstance.get('/passes/search', { params }),

  // New methods for Equipment
  searchEquipment: (params) => axiosInstance.get('/equipment/search', { params }),

  // New methods for Exercises
  searchExercises: (params) => axiosInstance.get('/exercises/search', { params }),

  // New methods for Places
  searchPlaces: (params) => axiosInstance.get('/places/search', { params }),

  // New method for User Ratings
  searchUserRatings: (params) => axiosInstance.get('/users/ratings/search', { params }),

  // New method for Pass Bookings
  getBookingById: (passId, userId) => axiosInstance.get(`/passes/${passId}/bookings/${userId}`),
  getUserPassBookingById: (userId, bookingId) => axiosInstance.get(`/users/${userId}/passbookings/${bookingId}`),

  // New method for Base Load
  getBaseLoad: () => axiosInstance.get('/baseload'),

  // Add this new method to the api object
  getPublishedPublicMessages: () => axiosInstance.get('/messages', {
    params: {
      audienceType: 'Public',
      status: 'Published',
      publishedBefore: new Date().toISOString()
    }
  }),

  // Update this method to handle subscription status updates
  updateSubscriptionStatus: (userId, subscriptionId, subscriptionData) => 
    axiosInstance.put(`/users/${userId}/memberships/${subscriptionId}`, subscriptionData),

  // Add this new method
  updateUserFCMToken: (userId, fcmToken) => {
    console.log('Updating FCM token for user:', userId, 'Token:', fcmToken);
    return axiosInstance.put(`/users/${userId}/fcm-token`, { fcmToken });
  },

  sendTestNotification: (userId) => {
    console.log('Sending test notification for user:', userId);
    return axiosInstance.post(`/users/${userId}/send-test-notification`);
  },

  // ... (other methods)
};

export default api;