<template>
  <div class="board-page">
    <h1>Styrelsen</h1>
    <p>Här presenterar vi medlemmarna i Banan Outdoors styrelse.</p>
    <ul class="board-members">
      <li v-for="member in boardMembers" :key="member.id">
        <h2>{{ member.name }}</h2>
        <p><strong>Position:</strong> {{ member.position }}</p>
        <p>{{ member.description }}</p>
      </li>
    </ul>
  </div>
</template>

<script>
import { ref } from 'vue'

export default {
  name: 'BoardPage',
  setup() {
    const boardMembers = ref([
      { id: 1, name: 'Helene Bergström', position: 'Ordförande', description: 'Helene har varit medlem sedan starten och brinner för utomhusträning.' },
      { id: 2, name: 'Leif Borgstedt', position: 'Sekreterare', description: 'Leif ansvarar för att dokumentera styrelsens arbete och beslut.' },
      { id: 3, name: 'Markus Reimer', position: 'Kassör', description: 'Markus ansvarar för att ekonomin hålls i balans.' },
      // Add more board members as needed
    ])

    return { boardMembers }
  }
}
</script>
