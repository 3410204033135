<template>
  <div class="social-media-page">
    <h1>Följ oss på sociala medier</h1>
    <p>Håll dig uppdaterad med våra senaste nyheter och evenemang genom att följa oss på sociala medier.</p>
    <ul class="social-links">
      <li><a href="https://www.facebook.com/BananOutdoor" target="_blank">Facebook</a></li>
      <li><a href="https://www.facebook.com/groups/bananoutdoor" target="_blank">Facebook Grupp</a></li>
      <li><a href="https://www.instagram.com/bananoutdoor/" target="_blank">Instagram</a></li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'SocialMediaPage'
}
</script>
