<template>
  <div
    id="app"
    :key="forceUpdate"
  >
    <div v-if="isAuthReady">
      <MainMenu
        :isAuthenticated="isAuthenticated"
        :isStaff="isStaff"
      />
      <div class="content">
        <div
          ref="backgroundLogo"
          class="background-logo"
          :style="{ backgroundImage: `url(${backgroundLogoUrl})` }"
        />
        <div class="content-wrapper">
          <router-view v-slot="{ Component }">
            <keep-alive>
              <component :is="Component" />
            </keep-alive>
          </router-view>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useMainStore } from '@/stores'
import { ref, computed, onMounted, watch, onUnmounted } from 'vue'
import { getAuth, onAuthStateChanged, getRedirectResult } from 'firebase/auth'
import MainMenu from '@/components/MainMenu.vue'
import { fcmService } from '@/services/fcmService'
import { api } from '@/services/api'

const store = useMainStore()
const isAuthReady = ref(false)
const forceUpdate = ref(0)

const isAuthenticated = computed(() => store.isAuthenticated)
const isStaff = computed(() => store.isStaff)

const backgroundLogoUrl = computed(() => {
  return `${process.env.BASE_URL}logo-icon.webp`
})

onMounted(async () => {
  try {
    console.log('App.vue mounted, initializing...');
    await fcmService.init();
    const auth = getAuth();
    
    // Handle redirect result
    console.log('Checking for redirect result in App.vue');
    const result = await getRedirectResult(auth);
    if (result) {
      console.log('Redirect result found in App.vue:', result);
      await handleUserAuthentication(result.user);
    } else {
      console.log('No redirect result found in App.vue');
    }

    onAuthStateChanged(auth, async (user) => {
      console.log('Auth state changed:', user ? 'User logged in' : 'User logged out');
      if (user) {
        await handleUserAuthentication(user);
      } else {
        await store.setUser(null)
      }
      isAuthReady.value = true
    })
  } catch (error) {
    console.error('Error initializing app:', error);
  }
})

const handleUserAuthentication = async (user) => {
  try {
    console.log('Handling user authentication in App.vue:', user);
    await store.fetchCurrentUser(user.email);
    await initializeFCM(store.user.id);
  } catch (error) {
    console.error('Error handling user authentication:', error);
  }
}

watch(() => store.isAuthenticated, () => {
  forceUpdate.value++
})

const initializeFCM = async (userId, token = null) => {
  if (!token) {
    token = await fcmService.getToken();
  }
  if (token) {
    if (token !== store.user.fcmToken) {
      try {
        await api.updateUserFCMToken(userId, token);
        store.updateUserFCMToken(token);
      } catch (error) {
        console.error('Failed to send FCM token to server:', error);
      }
    }
  }

  fcmService.onMessageListener().then((payload) => {
    console.log('Received foreground message:', payload);
    // Handle the received message here
  });
}

let tokenRefreshInterval;

const checkAndUpdateToken = async () => {
  if (store.user && store.user.id) {
    const newToken = await fcmService.getToken();
    if (newToken && newToken !== store.user.fcmToken) {
      await initializeFCM(store.user.id, newToken);
    }
  }
};

onMounted(() => {
  tokenRefreshInterval = setInterval(checkAndUpdateToken, 30 * 60 * 1000);
});

onUnmounted(() => {
  if (tokenRefreshInterval) {
    clearInterval(tokenRefreshInterval);
  }
});
</script>

<style>
#app {
  display: flex;
  font-family: Arial, sans-serif;
  width: 100%;
  min-height: 100vh;
}

.content {
  flex: 1;
  margin-left: 80px;
  display: flex;
  flex-direction: column;
  margin-top: 60px; /* Adjust this value based on your menu height */
}

.background-logo {
  position: fixed;
  top: 0;
  left: 80px;
  right: 0;
  bottom: 0;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  opacity: 0.1;
  z-index: 0;
  /* background-image is now set inline using v-bind:style */
}

.content-wrapper {
  position: relative;
  z-index: 1;
  padding: 20px;
  flex: 1;
  width: 100%;
}

@media (max-width: 768px) {
  .content {
    margin-left: 60px;
  }

  .background-logo {
    left: 60px;
  }
}
</style>
