<template>
  <div class="training-page">
    <h1>Vår träning</h1>
    
    <section>
      <h2>Funktionell träning utomhus</h2>
      <p>
        Banan Outdoor erbjuder funktionell träning utomhus som passar alla, oavsett tidigare erfarenhet eller konditionsnivå. 
        Vår träning kombinerar styrka, kondition och rörlighet för att ge dig en allsidig och effektiv träningsupplevelse.
      </p>
    </section>

    <section>
      <h2>Anpassad för alla nivåer</h2>
      <p>
        Våra pass är utformade så att alla kan delta och utmana sig själva på sin egen nivå. 
        Oavsett om du är nybörjare eller erfaren idrottare, kan du anpassa övningarna efter dina förutsättningar och mål.
      </p>
    </section>

    <section>
      <h2>Naturens gym</h2>
      <p>
        Vi utnyttjar naturen och dess element som vårt gym. Genom att träna utomhus får du inte bara fysiska fördelar, 
        utan också mental återhämtning och energi från den friska luften och vackra omgivningen.
      </p>
    </section>

    <section>
      <h2>Varierad och rolig träning</h2>
      <p>
        Våra pass varierar från gång till gång för att hålla träningen intressant och utmanande. 
        Vi använder olika träningsmetoder och övningar för att ge dig en allsidig och rolig träningsupplevelse.
      </p>
    </section>

    <section>
      <h2>Gemenskap och motivation</h2>
      <p>
        Att träna tillsammans med andra ger extra motivation och gör träningen roligare. 
        Hos Banan Outdoor blir du en del av en gemenskap där vi stöttar och peppar varandra till att nå våra mål.
      </p>
    </section>

    <section>
      <h2>Året runt-träning</h2>
      <p>
        Vi tränar utomhus året runt, oavsett väder. Detta stärker inte bara kroppen utan också sinnet, 
        och ger dig en unik upplevelse av att vara ett med naturen i alla årstider.
      </p>
    </section>
  </div>
</template>

<script>
export default {
  name: 'TrainingPage'
}
</script>

<style scoped>
.training-page {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

h1 {
  color: #2c3e50;
  font-size: 2.5em;
  margin-bottom: 30px;
}

h2 {
  color: #34495e;
  font-size: 1.8em;
  margin-top: 30px;
  margin-bottom: 15px;
}

p {
  line-height: 1.6;
  margin-bottom: 20px;
}

section {
  margin-bottom: 40px;
}
</style>
