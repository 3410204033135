<template>
  <v-dialog
    v-model="showModalComputed"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
    :retain-focus="false"
  >
    <v-card
      v-if="currentSessionComputed || isCreatingNew"
      class="edit-modal"
    >
      <v-toolbar
        dark
        color="primary"
      >
        <v-btn
          icon
          dark
          @click="closeModal"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>{{ isEditingComputed ? 'Redigera pass' : 'Skapa nytt pass' }}</v-toolbar-title>
        <v-spacer />
        <v-toolbar-items>
          <v-btn
            text
            @click="saveSession"
          >
            Spara
          </v-btn>
          <v-btn
            v-if="isEditingComputed"
            icon
            @click="confirmDelete"
          >
            <v-icon color="red">
              mdi-delete
            </v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>
      
      <v-card-text class="edit-modal-content">
        <form
          class="edit-form"
          @submit.prevent="saveSession"
        >
          <!-- Session details -->
          <div class="session-details">
            <div class="date-time-container">
              <div class="form-group date-group">
                <label for="date">Datum:</label>
                <input
                  id="date"
                  v-model="sessionDate"
                  type="date"
                  required
                >
              </div>
              <div class="form-group time-group">
                <label for="time">Tid:</label>
                <input
                  id="time"
                  v-model="sessionTime"
                  type="time"
                  required
                >
              </div>
            </div>
            <div class="form-group trainer-type-group">
              <div class="trainer-type-container">
                <CustomSelect
                  v-model="currentSessionComputed.type"
                  :options="sessionTypesComputed"
                  :show-text="false"
                  class="session-type-select"
                />
                <div class="trainer-input-container">
                  <label for="trainer">Tränare:</label>
                  <v-autocomplete
                    v-model="selectedTrainer"
                    :items="formattedTrainers"
                    item-title="name"
                    item-value="id"
                    label="Tränare"
                    placeholder="Välj tränare"
                    return-object
                    @update:model-value="updateTrainer"
                  >
                    <template #item="{ item, props }">
                      <v-list-item
                        v-bind="props"
                        :title="item.raw.name"
                      />
                    </template>
                  </v-autocomplete>
                </div>
              </div>
            </div>
          </div>

          <!-- Segments -->
          <div class="segments-container">
            <draggable
              v-model="currentSessionComputed.segments"
              item-key="id"
              handle=".drag-handle"
            >
              <template #item="{ element, index }">
                <div class="segment">
                  <div class="segment-header">
                    <v-icon class="drag-handle">
                      mdi-drag
                    </v-icon>
                    <input
                      v-model="element.label"
                      placeholder="Segment label"
                    >
                    <CustomSelect
                      v-model="element.type"
                      :options="segmentTypes"
                      @update:model-value="updateSegmentType(index, $event)"
                    />
                    <v-btn
                      icon
                      @click="removeSegment(index)"
                    >
                      <v-icon color="red">
                        mdi-delete
                      </v-icon>
                    </v-btn>
                  </div>
                  <!-- Records within segment -->
                  <div class="records-container">
                    <draggable
                      v-model="element.records"
                      item-key="id"
                      handle=".drag-handle"
                    >
                      <template #item="{ element: record }">
                        <div class="record">
                          <!-- First Row: Drag Handle, Record Type Select, and Exercise -->
                          <v-row
                            align="center"
                            class="record-header"
                          >
                            <!-- Drag Handle -->
                            <v-col
                              cols="auto"
                              class="d-flex align-center"
                            >
                              <v-icon class="drag-handle">
                                mdi-drag
                              </v-icon>
                            </v-col>

                            <!-- Record Type Select -->
                            <v-col
                              cols="3"
                              sm="1"
                              md="1"
                            >
                              <CustomSelect
                                v-model="record.recordType"
                                :options="recordTypeOptionsFormatted"
                                class="record-type-select"
                              />
                            </v-col>

                            <!-- Exercise Autocomplete -->
                            <v-col
                              cols="7"
                              sm="8"
                              md="9"
                            >
                              <v-autocomplete
                                v-model="record.exerciseId"
                                :items="formattedExercises"
                                item-title="name"
                                item-value="id"
                                placeholder="Välj övning"
                                dense
                                @update:model-value="updateExercise(record)"
                                @input="handleExerciseInput(record, $event)"
                              />
                            </v-col>
                          </v-row>

                          <!-- Second Row: Repetitions and Description -->
                          <v-row
                            align="center"
                            class="record-details"
                          >
                            <v-col cols="4">
                              <v-text-field
                                v-model.number="record.repetitions"
                                label="Repetitioner"
                                dense
                              />
                            </v-col>
                            <v-col cols="8">
                              <v-text-field
                                v-model="record.description"
                                label="Beskrivning"
                                dense
                              />
                            </v-col>
                          </v-row>

                          <!-- Third Row: Place and Delete Button -->
                          <v-row
                            align="center"
                            class="record-actions"
                          >
                            <v-col>
                              <v-autocomplete
                                v-model="record.placeId"
                                :items="places"
                                item-title="name"
                                item-value="id"
                                placeholder="Välj plats"
                                dense
                                @update:model-value="updatePlace(record)"
                              />
                            </v-col>
                            <v-col cols="auto">
                              <v-btn
                                icon
                                @click="removeRecord(element, record)"
                              >
                                <v-icon color="red">
                                  mdi-delete
                                </v-icon>
                              </v-btn>
                            </v-col>
                          </v-row>
                        </div>
                      </template>
                    </draggable>
                    <v-btn
                      text
                      @click="addRecord(element)"
                    >
                      Lägg Till Övning
                    </v-btn>
                  </div>
                </div>
              </template>
            </draggable>
            <v-btn @click="addSegment">
              Lägg till avsnitt
            </v-btn>
          </div>

          <!-- Add this section at the bottom of the form -->
          <div class="map-container">
            <div class="session-stats">
              <p><strong>Totalt avstånd:</strong> {{ totalDistance.toFixed(2) }} meter</p>
              <p><strong>Höjdmeter:</strong> {{ totalAscent.toFixed(2) }} meter</p>
            </div>
            <div
              id="session-map"
              ref="mapContainer"
              style="width: 100%; height: 400px; border: 1px solid #ccc;"
            ></div>
          </div>
        </form>
      </v-card-text>
    </v-card>
  </v-dialog>

  <!-- Add this confirmation dialog -->
  <v-dialog
    v-model="showDeleteConfirmation"
    max-width="300"
  >
    <v-card>
      <v-card-title class="headline">
        Bekräfta borttagning
      </v-card-title>
      <v-card-text>Är du säker på att du vill ta bort detta pass?</v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          color="green darken-1"
          text
          @click="showDeleteConfirmation = false"
        >
          Avbryt
        </v-btn>
        <v-btn
          icon
          @click="deleteSession"
        >
          <v-icon color="red">
            mdi-delete
          </v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>

  <!-- Add this dialog for creating a new exercise -->
  <v-dialog
    v-model="showNewExerciseDialog"
    max-width="400px"
  >
    <v-card>
      <v-card-title>Create New Exercise</v-card-title>
      <v-card-text>
        <v-text-field
          v-model="newExerciseName"
          label="Exercise Name"
          required
        />
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          color="blue darken-1"
          text
          @click="showNewExerciseDialog = false"
        >
          Cancel
        </v-btn>
        <v-btn
          color="blue darken-1"
          text
          @click="createNewExercise"
        >
          Create
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { ref, computed, onMounted, watch, nextTick } from 'vue'
import { useToast } from 'vue-toastification'
import draggable from 'vuedraggable'
import CustomSelect from '@/components/CustomSelect.vue'
import api from '@/services/api'
import { v4 as uuidv4 } from 'uuid';
import L from 'leaflet'
import 'leaflet/dist/leaflet.css'

export default {
  name: 'EditSessionModal',
  components: {
    draggable,
    CustomSelect,
  },
  props: {
    showModal: {
      type: Boolean,
      required: true,
    },
    currentSession: {
      type: Object,
      required: true,
    },
    sessionTypes: {
      type: Object,
      required: true,
    },
    fetchSessions: Function,
    trainers: {
      type: Array,
      required: true
    },
    recordTypeOptions: {
      type: Object,
      required: true,
    },
    isCreatingNew: {
      type: Boolean,
      required: true
    },
    places: {
      type: Array,
      required: true
    }
  },
  emits: ['update:showModal', 'update:currentSession'],
  setup(props, { emit }) {
    const toast = useToast()
    const trainers = ref([])
    const exercises = ref([])
    const selectedTrainer = ref(null)
    
    // Add this for delete confirmation
    const showDeleteConfirmation = ref(false)

    const formattedTrainers = computed(() => {
      return props.trainers
        .map(user => ({
          id: user.id,
          name: `${user.firstName} ${user.lastName}`
        }))
        .sort((a, b) => a.name.localeCompare(b.name))
    })

    const formattedExercises = computed(() => {
      return exercises.value
        .map(exercise => ({
          id: exercise.id,
          name: exercise.name
        }))
        .sort((a, b) => a.name.localeCompare(b.name))
    })

    const formattedPlaces = computed(() => {
      return props.places
        .map(place => ({
          id: place.id,
          name: place.name
        }))
        .sort((a, b) => a.name.localeCompare(b.name))
    })

    const showModalComputed = computed({
      get: () => props.showModal,
      set: (value) => emit('update:showModal', value)
    })

    const currentSessionComputed = computed({
      get: () => props.currentSession || {},
      set: (value) => emit('update:currentSession', value)
    })

    const sessionTypesComputed = computed(() => Object.entries(props.sessionTypes).map(([value, { name, icon }]) => ({
      value: parseInt(value),
      name,
      icon
    })))

    const isEditingComputed = computed(() => !props.isCreatingNew && !!currentSessionComputed.value.id)

    const sessionDate = computed({
      get: () => {
        if (currentSessionComputed.value && currentSessionComputed.value.date) {
          return currentSessionComputed.value.date.split('T')[0]
        }
        return new Date().toISOString().split('T')[0] // Default to today's date
      },
      set: (value) => {
        if (currentSessionComputed.value) {
          const time = currentSessionComputed.value.date 
            ? currentSessionComputed.value.date.split('T')[1] 
            : '00:00:00'
          currentSessionComputed.value.date = `${value}T${time}`
        }
      }
    })

    const sessionTime = computed({
      get: () => {
        if (currentSessionComputed.value && currentSessionComputed.value.date) {
          return currentSessionComputed.value.date.split('T')[1].substring(0, 5)
        }
        return '00:00' // Default time
      },
      set: (value) => {
        if (currentSessionComputed.value) {
          const date = currentSessionComputed.value.date 
            ? currentSessionComputed.value.date.split('T')[0] 
            : new Date().toISOString().split('T')[0]
          currentSessionComputed.value.date = `${date}T${value}:00`
        }
      }
    })

    // Update segmentTypes to match the format expected by CustomSelect
    const segmentTypes = computed(() => ({
        0: { name: 'Slinga', icon: 'fad fa-route' },
        11: { name: 'Cirkelträning', icon: 'fad fa-circle-notch' },
        3: { name: 'Tabata', icon: 'fad fa-stopwatch' },
        1: { name: 'Stege', icon: 'fad fa-stairs' },
        2: { name: 'Pyramid', icon: 'fad fa-triangle' },
        4: { name: 'AMRAP', icon: 'fad fa-infinity' },
        5: { name: 'EMOM', icon: 'fad fa-clock' },
        6: { name: 'På tid', icon: 'fad fa-hourglass-half' },
        7: { name: 'Chipper', icon: 'fad fa-list-ol' },
        8: { name: 'Hero WOD', icon: 'fad fa-medal' },
        9: { name: 'Styrka', icon: 'fad fa-dumbbell' },
        10: { name: 'Teknik', icon: 'fad fa-bullseye-arrow' },
        12: { name: 'Intervalltrning', icon: 'fad fa-wave-square' }
    }))

    // Update recordTypeOptionsFormatted to be a computed property
    const recordTypeOptionsFormatted = computed(() => 
      Object.entries(props.recordTypeOptions).map(([value, { name, icon }]) => ({
        value,
        name,
        icon
      }))
    )

    const fetchTrainers = async () => {
      try {
        const response = await api.getUsers()
        trainers.value = response.data
      } catch (error) {
        console.error('Error fetching trainers:', error)
      }
    }

    const fetchExercises = async () => {
      try {
        const response = await api.getExercises()
        exercises.value = response.data
      } catch (error) {
        console.error('Error fetching exercises:', error)
      }
    }

    onMounted(() => {
      fetchTrainers()
      fetchExercises()
      initializeRecordTypes()
      if (!currentSessionComputed.value.date) {
        currentSessionComputed.value.date = new Date().toISOString()
      }
    })

    const updateTrainer = (trainer) => {
      if (trainer) {
        emit('update:currentSession', {
          ...props.currentSession,
          trainer: trainer.id,
          trainerName: trainer.name
        })
      } else {
        emit('update:currentSession', {
          ...props.currentSession,
          trainer: null,
          trainerName: ''
        })
      }
    }

    watch(() => props.currentSession.trainer, (newTrainerId) => {
      if (newTrainerId) {
        const trainer = formattedTrainers.value.find(t => t.id === newTrainerId)
        if (trainer) {
          selectedTrainer.value = trainer
        }
      } else {
        selectedTrainer.value = null
      }
    }, { immediate: true })

    // Add the formatDate function
    const formatDate = (dateString) => {
      const date = new Date(dateString)
      return date.toLocaleDateString('sv-SE', { year: 'numeric', month: '2-digit', day: '2-digit' })
    }

    const saveSession = async () => {
      try {
        console.log('Current session before saving:', JSON.stringify(currentSessionComputed.value, null, 2));

        if (!currentSessionComputed.value.date) {
          console.error('Date is missing from the current session');
          toast.error('Vänligen ange ett datum för passet.');
          return;
        }

        const sessionData = {
          id: currentSessionComputed.value.id,
          name: currentSessionComputed.value.name || `Session on ${formatDate(currentSessionComputed.value.date)}`,
          date: currentSessionComputed.value.date,
          type: currentSessionComputed.value.type,
          description: currentSessionComputed.value.description || '',
          trainer: currentSessionComputed.value.trainer,
          segments: currentSessionComputed.value.segments.map(segment => ({
            label: segment.label,
            type: segment.type,
            records: segment.records.map(record => {
              const transformedRecord = {
                recordType: record.recordType,
                placeId: record.placeId || ''
              }

              if (record.recordType === 0) {
                transformedRecord.exerciseId = record.exerciseId
                transformedRecord.exerciseName = record.exerciseName
                transformedRecord.repetitions = record.repetitions
                transformedRecord.description = record.description || ''
              } else if (record.recordType === 2) {
                transformedRecord.placeName = record.placeName
              }

              if (record.recordType === 1 || record.recordType === 4 ) {
                transformedRecord.duration = record.duration
                transformedRecord.unit = record.unit
              }

              return transformedRecord
            })
          })),
          metadata: currentSessionComputed.value.metadata,
          ratings: currentSessionComputed.value.ratings || [],
          weather: currentSessionComputed.value.weather || [],
          imageUrl: currentSessionComputed.value.imageUrl || [],
          distance: currentSessionComputed.value.distance,
          elevation: currentSessionComputed.value.elevation,
          cal: currentSessionComputed.value.cal,
          participants: currentSessionComputed.value.participants || [],
        }

        console.log('Saving session with transformed data:', JSON.stringify(sessionData, null, 2))

        let response
        if (isEditingComputed.value) {
          console.log(`Updating session with ID: ${sessionData.id}`)
          response = await api.updateSession(sessionData.id, sessionData)
        } else {
          console.log('Creating new session')
          response = await api.createSession(sessionData)
        }

        console.log('API response:', response)

        toast.success('Passet har sparats!')
        await props.fetchSessions() // Refresh the list
        closeModal() // Close the modal after successful save
      } catch (error) {
        console.error('Error saving session:', error)
        if (error.response) {
          console.error('Error response:', error.response.data)
        }
        toast.error('Kunde inte spara passet. Försök igen.')
      }
    }

    const closeModal = () => {
      emit('update:showModal', false)
    }

    const addSegment = () => {
      if (!currentSessionComputed.value) {
        console.error('No current session available')
        return
      }
      
      const newSegment = {
        id: uuidv4(),
        label: `Slinga ${currentSessionComputed.value.segments.length + 1}`,
        type: 0,
        records: []
      }
      
      currentSessionComputed.value.segments.push(newSegment)
    }

    const removeSegment = (index) => {
      currentSessionComputed.value.segments.splice(index, 1)
    }

    const updateSegmentType = (index, newType) => {
      if (currentSessionComputed.value.segments[index]) {
        currentSessionComputed.value.segments[index].type = newType
      }
    }

    const updateSessionType = (newType) => {
      currentSessionComputed.value.type = newType
      // Add any additional logic needed when session type changes
    }

    const mapContainer = ref(null)
    const mapInstance = ref(null)
    const totalDistance = ref(0)
    const totalAscent = ref(0)

    const initMap = async () => {
      console.log('Initializing map');
      await nextTick();
      if (mapContainer.value && !mapInstance.value) {
        console.log('Creating new map instance');
        mapInstance.value = L.map(mapContainer.value).setView([58.93208, 17.57791], 13);
        L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
          attribution: '© OpenStreetMap contributors'
        }).addTo(mapInstance.value);
      } else {
        console.log('Map already initialized or container not found');
      }
    };

    // Move this function inside the setup function
    const getExerciseName = (exerciseId) => {
      const exercise = exercises.value.find(e => e.id === exerciseId)
      return exercise ? exercise.name : 'Unknown Exercise'
    }

    // Modify the updateMap function to use the local getExerciseName function
    const updateMap = () => {
      console.log('Updating map...');
      if (!currentSessionComputed.value || !mapInstance.value) {
        console.warn('No current session or map instance');
        return;
      }

      console.log('Current session:', JSON.stringify(currentSessionComputed.value, null, 2));
      console.log('Places:', JSON.stringify(props.places, null, 2));

      mapInstance.value.eachLayer((layer) => {
        if (layer instanceof L.Marker || layer instanceof L.Polyline) {
          mapInstance.value.removeLayer(layer);
        }
      });

      const markers = [];
      const latlngs = [];

      currentSessionComputed.value.segments.forEach(segment => {
        console.log('Processing segment:', segment);
        segment.records.forEach(record => {
          console.log('Processing record:', record);
          const place = props.places.find(p => p.id === record.placeId);
          console.log('Place found:', place);
          if (place && place.location && place.location.coordinates) {
            const [lng, lat, elevation] = place.location.coordinates;
            console.log('Creating marker at:', lat, lng);
            const marker = L.marker([lat, lng]).addTo(mapInstance.value);
            marker.bindPopup(`<b>${place.name}</b><br>${getExerciseName(record.exerciseId)}`);
            markers.push(marker);
            latlngs.push([lat, lng, elevation]);
          } else {
            console.warn('Invalid place data for record:', record);
          }
        });
      });

      console.log('Markers created:', markers.length);
      console.log('Latlngs:', latlngs);

      if (latlngs.length > 0) {
        const polyline = L.polyline(latlngs.map(([lat, lng]) => [lat, lng]), { color: 'red' }).addTo(mapInstance.value);
        mapInstance.value.fitBounds(polyline.getBounds());
      } else {
        console.warn('No valid coordinates found');
        mapInstance.value.setView([58.93208, 17.57791], 13);
      }

      calculateDistanceAndElevation(latlngs);
    };

    const calculateDistanceAndElevation = (latlngs) => {
      totalDistance.value = 0
      totalAscent.value = 0

      for (let i = 0; i < latlngs.length - 1; i++) {
        const [lat1, lng1, elevation1] = latlngs[i]
        const [lat2, lng2, elevation2] = latlngs[i + 1]
        
        totalDistance.value += calculateHaversineDistance([lat1, lng1], [lat2, lng2])
        
        if (elevation1 !== undefined && elevation2 !== undefined) {
          const elevationChange = Math.abs(elevation2 - elevation1)
          totalAscent.value += elevationChange
        }
      }
    }

    const calculateHaversineDistance = (point1, point2) => {
      const R = 6371e3 // Earth's radius in meters
      const φ1 = point1[0] * Math.PI / 180
      const φ2 = point2[0] * Math.PI / 180
      const Δφ = (point2[0] - point1[0]) * Math.PI / 180
      const Δλ = (point2[1] - point1[1]) * Math.PI / 180

      const a = Math.sin(Δφ/2) * Math.sin(Δφ/2) +
                Math.cos(φ1) * Math.cos(φ2) *
                Math.sin(Δλ/2) * Math.sin(Δλ/2)
      const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a))

      return R * c
    }

    watch(currentSessionComputed, async (newSession) => {
      if (newSession) {
        console.log('Current session updated, initializing map');
        await nextTick();
        await initMap();
        console.log('Map initialized, updating map');
        updateMap();
      }
    }, { deep: true });

    watch(showModalComputed, async (newValue) => {
      if (newValue) {
        console.log('Modal opened, initializing map');
        await nextTick();
        await initMap();
        updateMap();
      }
    });

    const updateRecordType = (record, newType) => {
      record.recordType = newType
      // Add any additional logic needed when record type changes
    }

    // Add this watch to update the session type when it changes
    watch(() => currentSessionComputed.value.type, (newType) => {
      updateSessionType(newType)
    })

    // Add this method to initialize record types
    const initializeRecordTypes = () => {
      if (props.currentSession && props.currentSession.segments) {
        props.currentSession.segments.forEach(segment => {
          segment.records.forEach(record => {
            if (!record.recordType) {
              record.recordType = record.recordTypeString || 0 // Default to 'exercise' if not set
            }
          })
        })
      }
    }

    watch(() => props.currentSession, (newSession) => {
      if (newSession) {
        initializeRecordTypes()
      }
    }, { deep: true })

    // Add this method to get the correct label for a record type
    const getRecordTypeLabel = (recordType) => {
      const option = props.recordTypeOptions[recordType]
      return option ? option.name : recordType
    }

    const addRecord = (segment) => {
      if (!segment) {
        console.error('No segment provided')
        return
      }

      const newRecord = {
        id: uuidv4(),
        recordType: 0,
        exerciseId: null,
        exerciseName: '',
        repetitions: '',
        description: '',
        placeId: '',
        placeName: '',
        duration: '',
        unit: '',
        exerciseSearchResults: [],
        placeSearchResults: []
      }

      segment.records.push(newRecord)
    }

    const removeRecord = (segment, record) => {
      if (!segment || !record) {
        console.error('Segment or record not provided')
        return
      }
      const index = segment.records.findIndex(r => r.id === record.id)
      if (index !== -1) {
        segment.records.splice(index, 1)
      }
    }

    const deleteSession = async () => {
      try {
        await api.deleteSession(currentSessionComputed.value.id)
        toast.success('Passet har tagits bort!')
        await props.fetchSessions() // Refresh the list
        closeModal() // Close the modal after successful delete
      } catch (error) {
        console.error('Error deleting session:', error)
        toast.error('Kunde inte ta bort passet. Försök igen.')
      } finally {
        showDeleteConfirmation.value = false // Close the confirmation dialog
      }
    }

    const confirmDelete = () => {
      showDeleteConfirmation.value = true
    }

    const newExerciseName = ref('')
    const showNewExerciseDialog = ref(false)

    const createNewExercise = async () => {
      if (!newExerciseName.value) {
        toast.error('Please enter an exercise name')
        return
      }

      try {
        const newExercise = await api.createExercise({ name: newExerciseName.value })
        exercises.value.push(newExercise)
        toast.success('New exercise created successfully')
        showNewExerciseDialog.value = false
        newExerciseName.value = ''
        
        // Update the current record with the new exercise
        const currentRecord = currentSessionComputed.value.segments
          .flatMap(segment => segment.records)
          .find(record => record.exerciseId === null)
        
        if (currentRecord) {
          currentRecord.exerciseId = newExercise.id
          currentRecord.exerciseName = newExercise.name
        }
      } catch (error) {
        console.error('Error creating new exercise:', error)
        toast.error('Failed to create new exercise. Please try again.')
      }
    }

    const handleExerciseInput = (record, input) => {
      const existingExercise = formattedExercises.value.find(
        exercise => exercise.name.toLowerCase() === input.toLowerCase()
      )

      if (!existingExercise && input) {
        newExerciseName.value = input
        showNewExerciseDialog.value = true
      }
    }

    watch(() => props.places, (newPlaces) => {
      console.log('Places prop updated:', newPlaces);
    }, { deep: true, immediate: true });

    const updatePlace = (record) => {
      const place = props.places.find(p => p.id === record.placeId);
      if (place) {
        record.placeName = place.name;
      }
    };

    return {
      showModalComputed,
      currentSessionComputed,
      sessionTypesComputed,
      isEditingComputed,
      sessionDate,
      sessionTime,
      formattedTrainers,
      formattedExercises,
      formattedPlaces,
      selectedTrainer,
      updateTrainer,
      saveSession,
      closeModal,
      formatDate,
      addSegment,
      removeSegment,
      updateSegmentType,
      updateSessionType,
      segmentTypes,
      recordTypeOptionsFormatted,
      updateRecordType,
      getRecordTypeLabel,
      addRecord,
      removeRecord,
      confirmDelete,
      showDeleteConfirmation,
      deleteSession,
      newExerciseName,
      showNewExerciseDialog,
      createNewExercise,
      handleExerciseInput,
      mapContainer,
      totalDistance,
      totalAscent,
      getExerciseName,
      updateMap,
      updatePlace,
    }
  }
}
</script>

<style scoped>
/* Include styles related to the edit modal */

.v-card-text.edit-modal-content,
.v-dialog.v-overlay__content.v-card.v-card-text,
.v-dialog.v-overlay__content,
.v-card.v-card-text,
.v-card,
.v-card-text,
.v-dialog,
.v-overlay,
.v-dialog__content,
.v-dialog__content__sizer,
.v-overlay__content {
  padding: 0px !important;
}

.edit-modal {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.edit-modal-content {
  flex-grow: 1;
  overflow-y: auto;
  padding: 0px;
}

.edit-form {
  padding: 0px;
}

.session-details {
  margin-bottom: 20px;
}

.date-time-container {
  display: flex;
  gap: 10px;
}

.form-group {
  margin-bottom: 15px;
}

.trainer-type-container {
  display: flex;
  align-items: center;
  gap: 10px;
}

.session-type-select {
  width: 40px;
}

.segments-container {
  margin-top: 20px;
}

.segment {
  border: 1px solid #ddd;
  padding: 0px;
  margin-bottom: 10px;
}

.segment-header, .record-header {
  display: flex;
  align-items: center;
  gap: 0px !important;
  margin-bottom: 0px !important;
  margin-top: 0 !important;
}

.records-container {
  margin-left: 0px !important;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.record {
  border: 1px solid #e0e0e0;
  padding: 0px !important; /* Reduced padding */
  margin-bottom: 0px; /* Slightly reduced margin */
  border-radius: 8px;
}

.record-header,
.record-details,
.record-actions {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.drag-handle {
  cursor: grab;
  width: 12px !important; /* Reduced width */
  margin-right: 0px !important; /* Reduced margin */
  margin-left: 8px !important;
}

.trainer-input-container {
  flex-grow: 1;
}

.record-name {
  flex-grow: 1;
  font-weight: bold;
}

/* Add any additional styles for the v-autocomplete if needed */

.record-type-select {
  width: 20px !important; /* Adjusted width */
  margin-right: 0px !important; /* Reduced margin */
  margin-left: 0px !important;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

/* Add this new style for the exercise autocomplete */
.v-autocomplete.v-input {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  padding: 0px !important;
  padding-left: 0px !important;
  padding-right: 0px !important;
}

/* Adjust the layout of the record header */
.record-header {
  display: flex;
  align-items: center;
  gap: 0px !important; /* Reduced gap */
}

/* Adjust the column widths in the record header */
.record-header .v-col {
  padding: 0px !important; /* Reduced padding */
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

/* Adjust the exercise autocomplete column */
.record-header .v-col:nth-child(3) {
  flex-grow: 1;
  max-width: none;
  padding: 0px !important;
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.map-container {
  margin-top: 20px;
}

.session-stats {
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
}

#session-map {
  width: 100%;
  height: 400px;
}
</style>