<template>
  <div class="about-us-page">
    <h1>Om Banan Outdoor</h1>
    <p>Banan Outdoor är en ideell förening dedikerad till att främja utomhusträning och hälsosam livsstil i Trosa-området.</p>
    <h2>Vår vision</h2>
    <p>Att skapa en gemenskap där människor kan njuta av naturen samtidigt som de förbättrar sin hälsa och välbefinnande.</p>
    <h2>Vår historia</h2>
    <p>Grundad 2024 av en grupp entusiaster som ville dela sin passion för utomhusträning med andra men med en rik historia som började 2012</p>
  </div>
</template>

<script>
export default {
  name: 'AboutUsPage'
}
</script>
