<template>
  <div class="anatomy-visualization">
    <svg
      ref="svgElement"
      @click="handleSvgClick"
      v-html="svgContent"
    />
  </div>
</template>

<script setup>
import { ref, onMounted, watch, nextTick } from 'vue';
import axios from 'axios';
import anatomyData from '@/assets/anatomi.json';

const props = defineProps({
  exerciseId: {
    type: String,
    required: true
  },
  muscleGroups: {
    type: Array,
    required: true
  }
});

const emit = defineEmits(['muscle-clicked']);

const svgContent = ref('');
const svgElement = ref(null);

const loadSvgContent = async () => {
  try {
    const response = await axios.get('/anatomi.svg');
    svgContent.value = response.data;
  } catch (error) {
    console.error('Error loading SVG content:', error);
  }
};

const colorMuscles = () => {
  if (!svgElement.value) return;

  const allPaths = svgElement.value.querySelectorAll('path');
  allPaths.forEach(path => {
    const pathId = path.getAttribute('id');
    if (pathId === '2' || pathId === '3') {
      // Set contour paths to black
      path.setAttribute('fill', 'black');
    } else {
      // Set other paths to light grey initially
      path.setAttribute('fill', 'lightgrey');
    }
    // Add hover effect
    path.addEventListener('mouseover', () => path.style.cursor = 'pointer');
    path.addEventListener('mouseout', () => path.style.cursor = 'default');
  });

  // Color muscles based on muscleGroups data
  props.muscleGroups.forEach(muscleGroup => {
    const muscleIds = getMuscleIdsByName(muscleGroup.Name);
    if (muscleIds && muscleIds.length > 0) {
      muscleIds.forEach(id => {
        const path = svgElement.value.querySelector(`path[id="${id}"]`);
        if (path && id !== '2' && id !== '3') {
          const intensity = muscleGroup.Percentage / 100;
          // Use a gradient from light grey (rgb(211, 211, 211)) to red (rgb(255, 0, 0))
          const red = Math.round(211 + (255 - 211) * intensity);
          const green = Math.round(211 * (1 - intensity));
          const blue = Math.round(211 * (1 - intensity));
          const color = `rgb(${red}, ${green}, ${blue})`;
          path.setAttribute('fill', color);
        }
      });
    }
  });
};

const handleSvgClick = (event) => {
  const clickedPath = event.target.closest('path');
  if (clickedPath) {
    const muscleId = clickedPath.id;
    if (muscleId !== '2' && muscleId !== '3') {
      const muscleName = getMuscleNameById(muscleId);
      if (muscleName) {
        emit('muscle-clicked', muscleName);
      }
    }
  }
};

const getMuscleIdsByName = (muscleName) => {
  const muscle = anatomyData.find(m => m.name === muscleName);
  return muscle ? muscle.id : null;
};

const getMuscleNameById = (muscleId) => {
  const muscle = anatomyData.find(m => m.id.includes(muscleId));
  return muscle ? muscle.name : null;
};

onMounted(async () => {
  await loadSvgContent();
  nextTick(() => {
    colorMuscles();
  });
});

// Update the watch statement for muscleGroups
watch(() => props.muscleGroups, (newValue) => {
  console.log('muscleGroups updated:', newValue);
  nextTick(() => {
    colorMuscles();
  });
}, { deep: true, immediate: true });

watch(svgContent, () => {
  nextTick(() => {
    colorMuscles();
  });
});
</script>

<style scoped>
.anatomy-visualization {
  width: 100%;
  max-width: 500px;
  margin: 0 auto;
}

svg {
  width: 100%;
  height: auto;
}
</style>