<template>
  <div class="policies-page">
    <h1 class="page-title">Integritetspolicy för Banan Outdoor</h1>
    
    <section class="policy-section">
      <h2 class="section-title">Vilka uppgifter samlar vi in?</h2>
      <p>Vi samlar in de personuppgifter som krävs för att hantera ditt medlemskap och dina bokningar i vår träningsapp (om du väljer att använda den), såsom namn, kontaktinformation och bokningshistorik.</p>
    </section>

    <section class="policy-section">
      <h2 class="section-title">Hur använder vi dina uppgifter?</h2>
      <p>Vi använder dina uppgifter för att:</p>
      <ul>
        <li>Hantera ditt medlemskap och dina bokningar.</li>
        <li>Kommunicera med dig om dina bokningar och medlemskap.</li>
        <li>Förbättra våra tjänster och användarupplevelsen i vår app.</li>
      </ul>
    </section>

    <section class="policy-section">
      <h2 class="section-title">Lagring av uppgifter</h2>
      <p>Dina uppgifter lagras säkert inom EU och vi behåller dem endast så länge som krävs för de ändamål de samlades in för eller så länge vi är skyldiga att göra det enligt lag (t.ex. bokföringskrav).</p>
    </section>

    <section class="policy-section">
      <h2 class="section-title">Dina rättigheter</h2>
      <p>Du har rätt att begära:</p>
      <ul>
        <li>Utdrag av de uppgifter vi har om dig.</li>
        <li>Rättelse av felaktiga uppgifter.</li>
        <li>Radering av dina uppgifter som vi inte är skyldiga att behålla enligt lag.</li>
      </ul>
      <p>För att utöva dina rättigheter, vänligen kontakta oss på info@bananoutdoor.com.</p>
    </section>

    <section class="policy-section">
      <h2 class="section-title">Granska och hantera dina uppgifter</h2>
      <p>Du kan granska all information vi har samlat in om dig på din <router-link to="/profile">profilsida</router-link> under sektionen "GDPR-efterlevnad". Där kan du även:</p>
      <ul>
        <li>Se en översikt över dina personuppgifter</li>
        <li>Radera valfria uppgifter som inte är nödvändiga för ditt medlemskap</li>
        <li>Begära fullständig radering av all information om dig</li>
      </ul>
      <p>Vi rekommenderar att du regelbundet granskar dina uppgifter för att säkerställa att de är korrekta och uppdaterade.</p>
    </section>

    <section class="policy-section">
      <h2 class="section-title">Dataskyddsombud</h2>
      <p>Vårt dataskyddsombud är Markus Reimer. Vid frågor om hur vi hanterar dina personuppgifter, kontakta oss på info@bananoutdoor.com.</p>
    </section>

    <section class="policy-section">
      <h2 class="section-title">Ändringar av denna policy</h2>
      <p>Vi kan komma att uppdatera denna integritetspolicy och kommer i sådana fall att informera dig om ändringar. Senaste uppdatering: {{ lastUpdated }}.</p>
    </section>

    <section class="policy-section">
      <h2 class="section-title">Instruktioner för radering av data</h2>
      <p>Om du vill radera dina personuppgifter som inte behövs för lagliga eller bokföringsmässiga krav, följ dessa steg:</p>
      <ol>
        <li>Logga in på ditt konto och gå till din <router-link to="/profile">profilsida</router-link>.</li>
        <li>Navigera till sektionen "GDPR-efterlevnad".</li>
        <li>Välj alternativet "Begär fullständig radering av mina uppgifter".</li>
        <li>Bekräfta din begäran genom att följa instruktionerna på skärmen.</li>
      </ol>
      <p>Alternativt kan du skicka en begäran via e-post till info@bananoutdoor.com med ämnesraden "Begäran om dataradering".</p>
      <p>Vi kommer att behandla din begäran och återkoppla inom 30 dagar. Observera att vissa uppgifter kan behöva behållas av juridiska skäl eller för att uppfylla våra skyldigheter.</p>
    </section>

    <p class="last-updated">Senast uppdaterad: {{ lastUpdated }}</p>
  </div>
</template>

<script setup>
import { ref } from 'vue'

const lastUpdated = ref('2024-10-13')
</script>

<style scoped>
.policies-page {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  font-family: Arial, sans-serif;
  line-height: 1.6;
  color: #333;
}

.page-title {
  color: #2c3e50;
  border-bottom: 2px solid #3498db;
  padding-bottom: 10px;
  margin-bottom: 30px;
}

.policy-section {
  margin-bottom: 30px;
  background-color: #f8f9fa;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.section-title {
  color: #2980b9;
  margin-top: 0;
}

ul, ol {
  padding-left: 20px;
}

.last-updated {
  font-style: italic;
  text-align: right;
  margin-top: 30px;
  color: #7f8c8d;
}

a {
  color: #3498db;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}
</style>