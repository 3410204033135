<template>
  <div class="edit-page">
    <h1>Hantera övningar</h1>
    
    <!-- Add new exercise button -->
    <button
      class="btn btn-primary"
      @click="startAddingNewExercise"
    >
      Lägg till ny övning
    </button>

    <!-- Error display -->
    <div
      v-if="error"
      class="error-message"
    >
      {{ error }}
    </div>

    <!-- Dropdown list of all exercises -->
    <div
      v-if="!isAddingNewExercise && !selectedExercise"
      class="exercise-dropdown"
    >
      <select
        v-model="selectedExerciseId"
        @change="selectExerciseFromDropdown"
      >
        <option value="">
          Välj en övning att redigera
        </option>
        <option
          v-for="exercise in exercises"
          :key="exercise.id"
          :value="exercise.id"
        >
          {{ exercise.name }}
        </option>
      </select>
    </div>

    <!-- Search bar (only show when not adding/editing an exercise) -->
    <div
      v-if="!isAddingNewExercise && !selectedExercise"
      class="search-bar"
    >
      <input
        v-model="searchTerm"
        placeholder="Sök efter en övning..."
        @input="searchExercises"
      >
    </div>

    <!-- Search results (only show when not adding/editing an exercise) -->
    <div
      v-if="!isAddingNewExercise && !selectedExercise && searchResults.length > 0"
      class="search-results"
    >
      <div
        v-for="exercise in searchResults"
        :key="exercise.id"
        class="search-result-item"
        @click="selectExercise(exercise)"
      >
        {{ exercise.name }}
      </div>
    </div>

    <!-- Wrap the form in an error boundary -->
    <div v-if="currentExercise">
      <!-- Edit form or New Exercise form -->
      <div
        v-if="isAddingNewExercise || selectedExercise"
        class="edit-form"
      >
        <h2>{{ isAddingNewExercise ? 'Lägg till ny övning' : 'Redigerar: ' + currentExercise.name }}</h2>
        
        <!-- AIEnhanceExercise component -->
        <AIEnhanceExercise
          :exercise="currentExercise"
          :muscle-groups="muscleGroups"
          @exercise-enhanced="handleExerciseEnhanced"
          @enhance-error="handleEnhanceError"
        />

        <!-- Form fields -->
        <form @submit.prevent="submitForm">
          <v-text-field
            v-model="currentExercise.name"
            label="Namn"
            required
          />

          <v-textarea
            v-model="currentExercise.description"
            label="Beskrivning"
          />

          <v-slider
            v-model="currentExercise.complexityRating"
            label="Teknik (Complexity)"
            min="1"
            max="10"
            step="1"
            thumb-label="always"
          />

          <v-slider
            v-model="currentExercise.intensityRating"
            label="Energi (Intensity)"
            min="1"
            max="10"
            step="1"
            thumb-label="always"
          />

          <v-combobox
            v-model="currentExercise.muscleGroups"
            :items="muscleGroups"
            item-title="text"
            item-value="value"
            label="Muskelgrupper"
            multiple
            chips
            clearable
            @update:model-value="updateMuscleGroupIntensities"
          >
            <template #item="{ item, props }">
              <v-list-item
                v-bind="props"
                :title="item.raw.text"
              >
                <v-tooltip
                  activator="parent"
                  location="end"
                >
                  {{ item.raw.description }}
                </v-tooltip>
              </v-list-item>
            </template>
          </v-combobox>

          <div class="muscle-group-section">
            <div class="muscle-group-sliders">
              <!-- Muscle group intensities section -->
              <div v-if="currentExercise.muscleGroupIntensities && currentExercise.muscleGroupIntensities.length > 0">
                <h3>Muskelgrupp Intensitet</h3>
                <v-list>
                  <v-list-item
                    v-for="(muscleGroup, index) in currentExercise.muscleGroupIntensities"
                    :key="index"
                  >
                    <v-list-item-title>{{ getMuscleGroupName(muscleGroup.name) }}</v-list-item-title>
                    <v-list-item-subtitle>
                      <v-slider
                        v-model="muscleGroup.intensity"
                        :min="1"
                        :max="100"
                        :step="1"
                        label="Intensitet"
                        thumb-label="always"
                        @update:model-value="(value) => updateMuscleGroupIntensity(muscleGroup.name, value)"
                      />
                    </v-list-item-subtitle>
                  </v-list-item>
                </v-list>
              </div>
            </div>
            
            <div
              ref="anatomyVisualizationRef"
              class="anatomy-visualization"
            >
              <AnatomyVisualization
                :exercise-id="currentExercise.id || ''"
                :muscle-groups="anatomyVisualizationData"
                @muscle-clicked="handleMuscleClicked"
              />
            </div>
          </div>

          <v-select
            v-model="currentExercise.equipment"
            :items="equipment"
            item-title="name"
            item-value="id"
            label="Utrustning"
            return-object
          />

          <!-- Display existing images -->
          <div
            v-if="currentExercise.imageUrl && currentExercise.imageUrl.length > 0"
            class="existing-images"
          >
            <h3>Current Images</h3>
            <div class="image-grid">
              <div
                v-for="(image, index) in currentExercise.imageUrl"
                :key="index"
                class="image-item"
              >
                <img
                  :src="getFullImageUrl(image)"
                  :alt="currentExercise.name"
                >
                <v-btn
                  icon
                  small
                  color="error"
                  @click="removeImage(index)"
                >
                  <v-icon>mdi-close</v-icon>
                </v-btn>
                <span>Image {{ index + 1 }}</span>
              </div>
            </div>
          </div>

          <!-- Replace the existing ImageUploader components with a single one -->
          <ImageUploader
            :upload-url="getUploadUrl('image')"
            :accepted-file-types="'image/*,video/*'"
            :button-text="'Ladda upp bild eller video'"
            :button-color="'primary'"
            @upload-success="handleImageUpload"
            @upload-error="handleUploadError"
          />
          
          <div class="button-group">
            <v-btn
              type="submit"
              color="primary"
            >
              {{ isAddingNewExercise ? 'Lägg till' : 'Spara' }}
            </v-btn>
            <v-btn
              @click="cancelEditing"
            >
              Avbryt
            </v-btn>
            <v-btn
              v-if="!isAddingNewExercise"
              @click="copyExercise"
            >
              Kopiera
            </v-btn>
            <v-btn
              v-if="!isAddingNewExercise"
              color="error"
              @click="deleteExercise"
            >
              Ta bort
            </v-btn>
          </div>
        </form>
      </div>
    </div>
    <div v-else>
      Loading exercise data...
    </div>

    <!-- Add this section to display the selected exercise -->
    <div
      v-if="selectedExercise"
      class="selected-exercise"
    >
      <h3>Selected Exercise: {{ selectedExercise.Name }}</h3>
      <p>Description: {{ selectedExercise.Description }}</p>
      <!-- Add more fields as needed -->
    </div>

    <!-- Remove or comment out the AIEnhanceExercise component that was outside the edit form -->
    <!--
    <AIEnhanceExercise
      :exercise="currentExercise"
      :muscle-groups="muscleGroups"
      @exercise-enhanced="handleExerciseEnhanced"
      @enhance-error="handleEnhanceError"
    />
    -->
  </div>
</template>

<script>
import { ref, computed, onMounted, onUnmounted, watch } from 'vue'
import { useMainStore } from '@/stores'
import { api } from '@/services/api.js'
import anatomiData from '@/assets/anatomi.json'
import AnatomyVisualization from '@/components/AnatomyVisualization.vue'
import ImageUploader from '@/components/ImageUploader.vue' // Import the ImageUploader component
import { getImageUrl } from '@/utils/imageUtils';
import AIEnhanceExercise from '@/components/AIEnhanceExercise.vue'
import { useToast } from "vue-toastification";
import { v4 as uuidv4 } from 'uuid'; // You might need to install this package

export default {
  name: 'EditExercisesPage',
  components: {
    AnatomyVisualization,
    ImageUploader,
    AIEnhanceExercise
  },
  setup() {
    const store = useMainStore()
    const searchTerm = ref('')
    const searchResults = ref([])
    const selectedExercise = ref(null)
    const exercises = computed(() => {
      console.log('Computing exercises:', store.exercises)
      return store.exercises
    })
    const isAddingNewExercise = ref(false)
    const selectedExerciseId = ref('')
    const currentExercise = ref({
      name: '',
      description: '',
      complexityRating: 1,
      intensityRating: 1,
      muscleGroups: [],
      muscleGroupIntensities: [], // Ensure this is always initialized as an empty array
      equipment: '',
      imageUrl: [],
      media: []
    })

    const muscleGroups = computed(() => {
      return anatomiData.map(muscle => ({
        text: Array.isArray(muscle.namn) ? muscle.namn[0] : muscle.namn,
        value: muscle.name,
        description: muscle.beskrivningar
      }))
    })

    const equipment = computed(() => {
      return store.equipment.map(item => ({
        name: item.name,
        id: item.id
      }))
    })

    const anatomyVisualizationData = ref([])
    const anatomyVisualizationRef = ref(null)
    let resizeObserver = null
    const toast = useToast();
    const error = ref(null);

    const startAddingNewExercise = () => {
      isAddingNewExercise.value = true
      selectedExercise.value = null
      currentExercise.value = {
        name: '',
        description: '',
        complexityRating: 1,
        intensityRating: 1,
        muscleGroups: [],
        muscleGroupIntensities: [],
        equipment: null,
        imageUrl: [],
        media: [],
        newImageUrl: null,
        newMedia: []
      }
    }

    const searchExercises = () => {
      const term = searchTerm.value.toLowerCase()
      searchResults.value = term.length < 2
        ? []
        : exercises.value.filter(exercise => exercise.name.toLowerCase().includes(term))
    }

    const selectExerciseFromDropdown = () => {
      console.log('Selecting exercise with ID:', selectedExerciseId.value)
      if (selectedExerciseId.value) {
        const exercise = exercises.value.find(e => e.id === selectedExerciseId.value)
        console.log('Found exercise:', exercise)
        if (exercise) {
          selectExercise(exercise)
        } else {
          console.log('Exercise not found')
        }
      }
    }

    const selectExercise = (exercise) => {
      console.log('Selecting exercise:', exercise);
      try {
        isAddingNewExercise.value = false;
        selectedExercise.value = JSON.parse(JSON.stringify(exercise)); // Deep copy
        currentExercise.value = {
          id: exercise.id,
          name: exercise.name,
          description: exercise.description,
          complexityRating: exercise.complexityRating || 1,
          intensityRating: exercise.intensityRating || 1,
          muscleGroups: exercise.muscleGroups ? exercise.muscleGroups.map(mg => ({
            value: mg.Name,
            text: getMuscleGroupName(mg.Name)
          })) : [],
          muscleGroupIntensities: exercise.muscleGroups ? exercise.muscleGroups.map(mg => ({
            name: mg.Name,
            intensity: mg.Percentage
          })) : [],
          equipment: equipment.value.find(e => e.id === exercise.equipment) || null,
          imageUrl: exercise.imageUrl || [],
          media: Array.isArray(exercise.videoUrl) ? exercise.videoUrl : [],
          newImageUrl: null,
          newMedia: []
        };
        updateMuscleGroupIntensities(); // Update intensities after setting muscle groups
        searchResults.value = [];
        searchTerm.value = '';
        selectedExerciseId.value = exercise.id;
      } catch (err) {
        console.error('Error in selectExercise:', err);
        error.value = `Error selecting exercise: ${err.message}`;
      }
    };

    const getUploadUrl = (type) => {
      const fileName = `${type}-${uuidv4()}.jpg`;
      return `${process.env.VUE_APP_BLOB_STORAGE_URL}/${fileName}?${process.env.VUE_APP_BLOB_SAS_TOKEN}`;
    };

    const handleImageUpload = (imagePath) => {
      console.log('Image uploaded:', imagePath);
      currentExercise.value.imageUrl.push(imagePath);
      console.log('Updated exercise image URLs:', currentExercise.value.imageUrl);
    };

    const removeImage = (index) => {
      currentExercise.value.imageUrl.splice(index, 1);
    };

    const handleUploadError = (errorMessage) => {
      console.error('Upload error:', errorMessage);
      toast.error(errorMessage);
    };

    const submitForm = async () => {
      console.log('Current exercise before submission:', currentExercise.value);

      const exerciseData = {
        name: currentExercise.value.name,
        description: currentExercise.value.description,
        complexityRating: currentExercise.value.complexityRating,
        intensityRating: currentExercise.value.intensityRating,
        muscleGroups: currentExercise.value.muscleGroupIntensities.map(mg => ({
          Name: mg.name,
          Percentage: mg.intensity
        })),
        equipment: currentExercise.value.equipment ? currentExercise.value.equipment.id : null,
        imageUrl: currentExercise.value.imageUrl,
        videoUrl: Array.isArray(currentExercise.value.media) ? currentExercise.value.media : []
      };

      console.log('Submitting exercise data:', exerciseData);

      try {
        let updatedExercise;
        if (isAddingNewExercise.value) {
          updatedExercise = await store.addExercise(exerciseData);
        } else {
          updatedExercise = await api.updateExercise(currentExercise.value.id, exerciseData);
        }
        
        console.log('Response from server:', updatedExercise);

        // Update currentExercise with the response from the server
        currentExercise.value = {
          ...updatedExercise,
          imageUrl: Array.isArray(updatedExercise.imageUrl) && updatedExercise.imageUrl.length > 0 
            ? updatedExercise.imageUrl 
            : (updatedExercise.imageUrl || []),
          media: updatedExercise.videoUrl || []
        };
        
        console.log('Updated currentExercise:', currentExercise.value);
        
        await store.fetchExercises(); // Refresh the list of exercises
        toast.success(isAddingNewExercise.value ? 'Ny övning har lagts till!' : 'Övningen har uppdaterats!');
        cancelEditing();
      } catch (error) {
        console.error('Error submitting exercise:', error);
        toast.error(`Ett fel uppstod. Försök igen. Felmeddelande: ${error.message}`);
      }
    };

    const copyExercise = () => {
      const newExercise = JSON.parse(JSON.stringify(currentExercise.value))
      delete newExercise.id
      newExercise.name += ' (Kopia)'
      currentExercise.value = newExercise
      isAddingNewExercise.value = true
    }

    const deleteExercise = async () => {
      if (confirm('Är du säker på att du vill ta bort denna övning?')) {
        try {
          await api.deleteExercise(selectedExercise.value.id);
          toast.success('Övningen har tagits bort!');
          cancelEditing();
          fetchExercises(); // Refresh the list of exercises
        } catch (error) {
          console.error('Fel vid borttagning av övning:', error);
          toast.error('Kunde inte ta bort övningen. Försök igen.');
        }
      }
    };

    const cancelEditing = () => {
      isAddingNewExercise.value = false
      selectedExercise.value = null
      currentExercise.value = {
        name: '',
        description: '',
        complexityRating: 1,
        intensityRating: 1,
        muscleGroups: [],
        muscleGroupIntensities: [],
        equipment: '',
        imageUrl: [],
        media: []
      }
      selectedExerciseId.value = ''
    }

    const fetchExercises = async () => {
      try {
        console.log('Fetching exercises...')
        await store.fetchExercises()
        console.log('Exercises after fetch:', store.exercises)
        console.log('exercises computed value:', exercises.value)
        if (store.exercises.length === 0) {
          console.log('No exercises found. You may need to add some exercises first.')
        } else {
          console.log(`Fetched ${store.exercises.length} exercises`)
        }
      } catch (error) {
        console.error('Error fetching exercises:', error)
      }
    }

    const fetchEquipment = () => {
      store.fetchEquipment()
    }

    const updateMuscleGroupIntensities = () => {
      try {
        if (!currentExercise.value.muscleGroupIntensities) {
          currentExercise.value.muscleGroupIntensities = [];
        }
        currentExercise.value.muscleGroupIntensities = currentExercise.value.muscleGroups.map(mg => {
          const existingIntensity = currentExercise.value.muscleGroupIntensities.find(i => i.name === mg.value);
          return {
            name: mg.value,
            intensity: existingIntensity ? existingIntensity.intensity : 0
          };
        });
        // Ensure muscleGroups is updated to match muscleGroupIntensities
        currentExercise.value.muscleGroups = currentExercise.value.muscleGroupIntensities.map(mg => ({
          value: mg.name,
          text: getMuscleGroupName(mg.name)
        }));
        updateAnatomyVisualization();
      } catch (err) {
        console.error('Error in updateMuscleGroupIntensities:', err);
        error.value = `Error updating muscle group intensities: ${err.message}`;
      }
    }

    const getMuscleGroupName = (value) => {
      const muscle = muscleGroups.value.find(mg => mg.value === value);
      return muscle ? muscle.text : value;
    }

    const updateAnatomyVisualization = () => {
      console.log('Updating anatomy visualization');
      anatomyVisualizationData.value = currentExercise.value.muscleGroupIntensities.map(mg => {
        const englishName = anatomiData.find(muscle => 
          (Array.isArray(muscle.namn) ? muscle.namn.includes(mg.name) : muscle.namn === mg.name)
        )?.name;
        return {
          Name: englishName || mg.name,
          Percentage: mg.intensity
        };
      });
      console.log('Updated anatomyVisualizationData:', anatomyVisualizationData.value);
    }

    const updateMuscleGroupIntensity = (name, intensity) => {
      const index = currentExercise.value.muscleGroupIntensities.findIndex(mg => mg.name === name);
      if (index !== -1) {
        currentExercise.value.muscleGroupIntensities[index].intensity = intensity;
      } else {
        currentExercise.value.muscleGroupIntensities.push({ name, intensity });
      }
      // Update muscleGroups to match muscleGroupIntensities
      currentExercise.value.muscleGroups = currentExercise.value.muscleGroupIntensities.map(mg => ({
        value: mg.name,
        text: getMuscleGroupName(mg.name)
      }));
      updateAnatomyVisualization();
    };

    const handleMuscleClicked = (muscleName) => {
      // Find the corresponding Swedish name
      const swedishName = anatomiData.find(muscle => 
        muscle.name.toLowerCase() === muscleName.toLowerCase() || 
        (Array.isArray(muscle.namn) ? muscle.namn.includes(muscleName) : muscle.namn.toLowerCase() === muscleName.toLowerCase())
      )?.namn;

      if (swedishName) {
        const muscleGroup = currentExercise.value.muscleGroups.find(mg => 
          mg.value === swedishName || (Array.isArray(swedishName) ? swedishName.includes(mg.value) : mg.value === swedishName)
        );
        
        if (muscleGroup) {
          const muscleGroupIntensity = currentExercise.value.muscleGroupIntensities.find(mg => 
            mg.name === swedishName || (Array.isArray(swedishName) ? swedishName.includes(mg.name) : mg.name === swedishName)
          );
          if (muscleGroupIntensity) {
            updateMuscleGroupIntensity(muscleName, muscleGroupIntensity.intensity > 0 ? 0 : 100);
          } else {
            updateMuscleGroupIntensity(muscleName, 100);
          }
        } else {
          currentExercise.value.muscleGroups.push({ 
            value: Array.isArray(swedishName) ? swedishName[0] : swedishName, 
            text: getMuscleGroupName(Array.isArray(swedishName) ? swedishName[0] : swedishName) 
          });
          updateMuscleGroupIntensity(muscleName, 100);
        }
      } else {
        console.warn(`No Swedish name found for muscle: ${muscleName}`);
      }
    };

    const handleExerciseEnhanced = ({ enhancedData, enhanceType }) => {
      console.log('Received enhanced data:', enhancedData);

      if (enhanceType === 'all' || enhanceType === 'description') {
        currentExercise.value.description = enhancedData.description;
      }

      if (enhanceType === 'all' || enhanceType === 'muscleGroups') {
        currentExercise.value.muscleGroupIntensities = enhancedData.muscleGroups.map(mg => ({
          name: mg.name,
          intensity: mg.intensity
        }));

        currentExercise.value.muscleGroups = enhancedData.muscleGroups.map(mg => ({
          value: mg.name,
          text: getMuscleGroupName(mg.name)
        }));
      }

      if (enhanceType === 'all' || enhanceType === 'ratings') {
        currentExercise.value.complexityRating = enhancedData.complexityRating;
        currentExercise.value.intensityRating = enhancedData.intensityRating;
        currentExercise.value.ratingExplanation = enhancedData.ratingExplanation;
      }

      // Update the anatomy visualization
      updateAnatomyVisualization();

      console.log('Updated currentExercise:', currentExercise.value);
    };

    const handleEnhanceError = (errorMessage) => {
      toast.error(errorMessage);
    };

    onMounted(() => {
      console.log('EditExercisesPage mounted')
      fetchExercises()
      fetchEquipment()

      resizeObserver = new ResizeObserver(() => {
        updateAnatomyVisualization()
      })

      if (anatomyVisualizationRef.value) {
        resizeObserver.observe(anatomyVisualizationRef.value)
      }
    })

    onUnmounted(() => {
      if (resizeObserver) {
        resizeObserver.disconnect()
      }
    })

    watch(() => store.exercises, (newValue) => {
      console.log('store.exercises changed:', newValue)
    }, { deep: true })

    watch(() => exercises.value, (newValue) => {
      console.log('exercises changed:', newValue)
    }, { deep: true, immediate: true })

    // Add this computed property
    const filteredMedia = computed(() => {
      return currentExercise.value.media.filter(url => url !== '')
    })

    watch(() => currentExercise.value.imageUrl, (newValue, oldValue) => {
      console.log('imageUrl changed from', oldValue, 'to', newValue);
    }, { immediate: true });

    // Add this watch effect
    watch(
      () => currentExercise.value.imageUrl,
      (newValue, oldValue) => {
        console.log('imageUrl changed:', { oldValue, newValue });
      },
      { immediate: true }
    );

    // Add this watch effect at the end of the setup function
    watch(
      () => currentExercise.value,
      (newValue) => {
        console.log('EditExercisesPage: currentExercise changed:', newValue);
      },
      { deep: true, immediate: true }
    );

    return {
      searchTerm,
      searchResults,
      selectedExercise,
      isAddingNewExercise,
      currentExercise,
      startAddingNewExercise,
      searchExercises,
      selectedExerciseId,
      selectExerciseFromDropdown,
      selectExercise,
      handleImageUpload,
      removeImage,
      handleUploadError,
      submitForm,
      copyExercise,
      deleteExercise,
      cancelEditing,
      exercises,
      equipment,
      muscleGroups,
      updateMuscleGroupIntensities,
      getMuscleGroupName,
      anatomyVisualizationData,
      updateAnatomyVisualization,
      anatomyVisualizationRef,
      filteredMedia,
      getFullImageUrl: getImageUrl,
      updateMuscleGroupIntensity,
      handleMuscleClicked,
      handleExerciseEnhanced,
      handleEnhanceError,
      getUploadUrl,
      error,
    }
  }
}
</script>

<style scoped>
/* ... (rest of the styles remain unchanged) ... */

/* Add this to ensure the tooltip text wraps properly */
.v-tooltip .v-overlay__content {
  max-width: 300px;
  white-space: normal;
  word-wrap: break-word;
}

.muscle-group-section {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 20px;
}

.muscle-group-sliders {
  flex: 1;
  margin-right: 20px;
}

.anatomy-visualization {
  flex: 1;
  max-width: 300px;
}

.existing-images {
  margin-bottom: 20px;
}

.image-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  gap: 10px;
}

.image-item {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.image-item img {
  width: 100%;
  height: 150px;
  object-fit: cover;
  border-radius: 4px;
}

.image-item .v-btn {
  position: absolute;
  top: 5px;
  right: 5px;
}

.image-item span {
  margin-top: 5px;
  font-size: 0.8em;
  text-align: center;
}
</style>